
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

:root {

  --font-best: 'Avenir', sans-serif;
  --font-alt: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
  --font-reg: 'GeographWeb Regular', "Helvetica", "Arial", sans-serif;
  --font-base: 'Avenir', sans-serif;
  --font-now:  'Roboto', sans-serif;
  --color-golden: #DCCA87;
  --color-black: #000000;
  --color-gray: #545454;
  --color-crimson: #F5EFDB;
  --color-grey: #AAAAAA;
  --color-white: #FFFFFF;
  --color-blue: #00c3ff;
  --color-darkgreen: #283F05;
  --color-algeagreen: #00c07f;
  --color-secondary: #e47194;
  --color-darkblue:  #243659;

  /* form */
  --dark-blue: #21213b;
  --medium-blue: #2f2f4f;
  --soft-blue: #708aff;
  --blue-sky: #1865f2;
  --white: #fff;

}

fieldset {
  border: 0;
  padding-block-start: 0;
  margin-inline-end: 0;
  padding-block-end: 0;
  margin-inline-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}


::-webkit-scrollbar {
  background-color: #f4f4f4;
  width: 0.6rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-algeagreen);
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
  body::-webkit-scrollbar {
    display: none;
  }
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}



:root {
  --font-base: "Avenir", sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}


@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./fonts/AvenirNextLTPro-Regular.otf) format('woff');

}


/* @font-face {
  font-family: 'AvenirNextLTPro';
  src: url(./fonts/AvenirNextLTPro-Bold.otf) format('embedded-opentype'),  
      url(./fonts/AvenirNextLTPro-It.otf) format('woff'), 
      url(./fonts/AvenirNextLTPro-Regular.otf)  format('truetype');
  font-weight: 500;
  font-style: normal;

} */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}


/* 
body{
  width: 100%;
  margin: auto;
} */