.app__social-container-in{
    width: 100%;
    display: flex;
    margin: 0rem auto 0rem;

    @media screen and (max-width: 950px) {
        flex-direction: column;
    }

    .app__socialb-cont{
        width: 50%;
        max-width: 550px;
        margin: auto;
        padding-right: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        @media screen and (max-width: 950px) {
            width: 90%;
            margin: auto;
            padding-bottom: 1rem;
        }
        @media screen and (max-width: 650px) {
           padding-left: 0px;
        }
        h1{
            font-size: 55px;
            line-height: 60px;
            max-width: 400px;
            font-family: var(--font-alt);
            @media screen and (max-width: 550px) {
                font-size: 35px;
                line-height: 44px;
                text-align: left;
            }

        }

        p{
            font-size: 20px;
            // max-width: 850px;
            line-height: 33px;
            font-family: var(--font-best);
            padding-bottom: 10px;
            @media screen and (max-width: 550px) {
                font-size: 17px;
                line-height: 27px;
            }
        }

        ol{
            padding-left: 1rem;
            margin-top: 1rem;
            max-width: 400px;

            li{
                font-size: 17px;
                font-family: var(--font-best);
                list-style: disc;
                padding-bottom:10px;
                line-height: 27px;
                letter-spacing: 1px;
                @media screen and (max-width: 550px) {
                    font-size: 15px;
                    line-height: 20px;
                }
              
            }
        }

    }
    .img-social-benefit{
        width: 50%;
        min-height: 500px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        // gap: 5px;
        @media screen and (max-width: 950px) {
            width: 100%;
            // min-height: 500px;
        }
        @media screen and (max-width: 650px) {
            width: 100%;
            grid-template-columns: repeat(1, 1fr);

        }

        .img{
            width: 100%;
            height: 500px;
            background: #FFFFFF;
            // border: 1px solid #EBEFFA;
            box-sizing: border-box;
            box-shadow: 0px 10px 20px rgb(0 58 208 / 5%);
            // border-radius: 15px;
            // margin: 1rem auto;
            cursor: pointer;
            transition: all .5s cubic-bezier(.8,.5,.2,1.4);
            overflow: hidden;
            position: relative;
            @media screen and (max-width: 950px) {
                width: 100%;
                // height: 250px;
            }
            &:hover{
                box-shadow: 0px 0px 30px rgb(0, 0, 0, 0.3);  
                // box-shadow: 0px 10px 20px rgb(0 58 208 / 5%);
                transition: all .5s cubic-bezier(.8,.5,.2,1.4);  
                transform: scale(.97);

                img{
                    transition: all .5s cubic-bezier(.8,.5,.2,1.4);
                    transform: scale(1.6) rotate(20deg);
                    filter: blur(5px);
                }
                p{
                    left:0px;
                    transition: all .7s ease-in-out;
                    clip-path: circle(75%);
                }
            }    

            img{
                width: 100%;
                height: 100%;
                // height: 260px;
                object-fit: cover;
            }
            p{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                top:0px;
                left:0px;
                background-color: rgba(255,255,255,.4);
                background-color: rgba(5, 2, 26, 0.7);
                width:100%;
                height:100%;
                transition: all .7s ease-in-out;
                padding: 20px;
                box-sizing: border-box;
                clip-path: circle(0% at 100% 100%);
                font-size: 26px;
                line-height: 34px;
                font-family: var(--font-best);
                color: var(--color-darkblue);
                color: #EBEFFA;
                margin: auto;
                text-align: center;
                padding: 0 3px;
            }
        }
    }
}