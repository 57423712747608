.app__cost-head{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3rem auto;

    h1{
        font-size: 45px;
        font-family: var(--font-alt);
        font-weight: normal;

    }
    .app__cost-line{
        width: 70px;
        height: 10px;
        border-bottom: 3px solid var(--color-black);
    }
}

.isntrr{
    width: 90%;
    
    @media screen and (max-width: 850px) {
        width: 90%;
    }
    
    p{
        font-size: 15px;
        font-family: var(--font-best);
        font-style: italic;
        @media screen and (max-width: 550px) {
            font-size: 16px;
        }
    }
}

.app__cost-container{
    width: 90%;
    margin: 2rem auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;

    @media screen and (max-width: 850px) {
        width: 90%;
    }
    @media screen and (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
    }
    .one{
        width: 100%;
        height: 300px;
        // background: #FFFFFF;
        // border: 1px solid #EBEFFA;
        // box-sizing: border-box;
        // background-color: rgb(13, 77, 77);
        // background: #FFFFFF;
        // border: 1px solid #EBEFFA;
        box-sizing: border-box;
        box-shadow: 0px 10px 20px rgb(0 58 208 / 5%);
        // border-radius: 15px;
        // margin: 1rem auto;
        cursor: pointer;
        transition: all .5s cubic-bezier(.8,.5,.2,1.4);
        overflow: hidden;
        position: relative;
        &:hover{
            box-shadow: 0px 0px 30px rgb(0, 0, 0, 0.3);  
            // box-shadow: 0px 10px 20px rgb(0 58 208 / 5%);
            transition: all .5s cubic-bezier(.8,.5,.2,1.4);  
            transform: scale(.97);

            img{
                transition: all .5s cubic-bezier(.8,.5,.2,1.4);
                transform: scale(1.6) rotate(20deg);
                filter: blur(5px);
            }
            p{
                left:0px;
                transition: all .7s ease-in-out;
                clip-path: circle(75%);
            }
        }    
        
        @media screen and (min-width: 1250px) {
            width: 100%;
        }
        @media screen and (max-width: 850px) {
            // margin-bottom: 1rem;
        }
        img{
            width: 100%;
            // height: 260px;
            height: 100%;
            object-fit: cover;
            
        }
        p{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            top:0px;
            left:0px;
            background-color: rgba(255,255,255,.4);
            background-color: rgba(5, 2, 26, 0.7);
            width:100%;
            height:100%;
            transition: all .7s ease-in-out;
            padding: 20px;
            box-sizing: border-box;
            clip-path: circle(0% at 100% 100%);
            font-size: 26px;
            line-height: 34px;
            font-family: var(--font-best);
            color: var(--color-darkblue);
            color: #EBEFFA;
            margin: auto;
            text-align: center;
            padding: 0 3px;
        }
    }
}

.app__content-below{
    width: 80%;
    margin: 1rem auto 2rem ;
    display: flex;
    align-items: center;
    justify-content: center;

    p{
        font-size: 18px;
        font-family: var(--font-best);
        text-align: center;
        max-width: 950px;

    }

    @media screen and (max-width: 850px) {
        width: 90%;
    }
}

.app__cost-btn{
    width: 80%;
    margin: 1rem auto 2rem;
    
    @media screen and (min-width: 850px) {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @media screen and (max-width: 850px) {
        width: 90%;
    }

    button{
        background-color: var(--color-darkgreen);
        color: #FFFFFF;
        text-transform: capitalize;
        padding: 1rem 3.5rem;

        &:hover{
            color: #000;
            background-color: transparent;
            border: 1px solid var(--color-darkgreen);
            text-decoration: underline;
        }

        @media screen and (max-width: 850px) {
            width: 100%;
        }

    }
}
