.app__dataprog{
    width: 70%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    @media screen and (max-width: 850px) {
        width: 80%;
        
    }
    @media screen and (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
        
    }

    gap: 3rem;
    // background-color: #777;
    margin: 2rem auto ;

    .one{
        width: 100%;
        min-height: 200px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1.5rem;
        background: #FFFFFF;
        border: 1px solid #EBEFFA;
        box-sizing: border-box;
        box-shadow: 0px 10px 20px rgb(0 58 208 / 5%);
        border-radius: 15px;
        margin: 1rem auto;
        cursor: pointer;
        transition: all .5s cubic-bezier(.8,.5,.2,1.4);
        overflow: hidden;
        position: relative;

        @media screen and (max-width: 950px) {
            width: 100%;
        }

        &:hover{
            box-shadow: 0px 0px 30px rgb(0, 0, 0, 0.3);  
            // box-shadow: 0px 10px 20px rgb(0 58 208 / 5%);
            transition: all .5s cubic-bezier(.8,.5,.2,1.4);  
            transform: scale(.97);
            
          
                img{
                    transition: all .5s cubic-bezier(.8,.5,.2,1.4);
                    transform: scale(1.6) rotate(20deg);
                    filter: blur(5px);
                }
                h1{
                    transition: all .5s cubic-bezier(.8,.5,.2,1.4);
                    transform: scale(1.6) rotate(20deg);
                    filter: blur(5px);
                }
       
            p{
                left:0px;
                transition: all .7s ease-in-out;
                clip-path: circle(75%);
            }
        }
        .img{
            width: 130px;
            height: 130px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                @media screen and (min-width: 850px) {
                }
            }
        }

        h1{
            text-align: center;
            font-family: var(--font-best);
            font-weight: normal;
            font-size: 19px;
            line-height: 29px;

        }
        p{
            font-size: 22px;
            font-family: var(--font-best);
            text-align: center;
            line-height: 33px;
            position: absolute;
            top:0px;
            left:0px;
            background-color: rgba(255,255,255,.4);
            // background-color: rgba(137, 196, 244, .4);
            background-color: rgb(5, 5, 41,.7);
            color: #EBEFFA;
            width:100%;
            height:100%;
            transition: all .7s ease-in-out;
            padding: 20px;
            box-sizing: border-box;
            clip-path: circle(0% at 100% 100%);
            display: flex;
            align-items: center;
            justify-content: center;

        }
    }

}