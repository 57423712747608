// .app__foot {
//     flex: 1;
//     width: 100%;
//     flex-direction: column;
//     margin-top: 2rem;
  
//     @media screen and (max-width: 768px) {
//       flex-direction: column;
//     }
//   }


  .app__head-title{
    font-family: var(--font-alt);
    font-size: 20px;
    width: 70%;
    margin: auto;
    line-height: 66px;
    text-align: center;
    letter-spacing: .08rem;
    text-transform: capitalize;
    font-weight: 600;
    color: var(--color-darkblue);

    @media screen and (max-width: 1200px) {
      width: 100%;
      
    }
  }
  
  .app__footer-cards {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin: 4em 2rem 2rem;
  
    .app__footer-card {
      min-width: 290px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
  
      margin: 1rem 0;
      padding: 1rem;
      border-radius: 10px;
      cursor: pointer;
      background-color: #fef4f5;
  
      transition: all 0.3s ease-in-out;
  
      img {
        width: 40px;
        height: 40px;
        margin: 0 0.7rem;
      }
  
      p {
        font-weight: 500;
      }
      a {
        text-decoration: none;
        font-weight: 500;
      }
      &:hover {
        box-shadow: 0 0 25px #fef4f5;
      }
  
      @media screen and (max-width: 450px) {
        width: 100%;
      }
    }
  
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  
  .app__footer-cards .app__footer-card:last-child {
    background-color: #f2f7fb;
  }
  
  .app__footer-cards .app__footer-card:last-child:hover {
    box-shadow: 0 0 25px #f2f7fb;
  }

   .app__button-now{
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 1rem;
    padding-left: 1rem;
    button{
      font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
                text-transform: uppercase;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                font-weight: 600;
                font-size: 12px;
                letter-spacing: 3px;
                line-height: 1.5;
                color: var(--color-algeagreen);
                background-color: transparent;
                border: 2px solid var(--color-algeagreen);
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                
                padding: .8rem 2.2rem;
                border-radius: 9px;
                svg{
                    font-size: 1rem;
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                }
                &:hover{
                    background-color: var(--color-algeagreen);
                    // border: 1px solid var(--color-darkblue);
                    transition: .5s ease-in-out;
                    color: #fff;
                }
    }

  }
  
  .app__footer-form {
    width: 60%;
    flex-direction: column;
    margin: 1rem 2rem;
    // background-image: url('../../assets/bg1.jpg');
    background-size: cover;
    background-position: center;

   
    div {
      width: 80%;
  
      margin: 0.75rem 0;
      border-radius: 10px;
      cursor: pointer;
      // background-color: var(--primary-color);
  
      transition: all 0.3s ease-in-out;
  
      input,
      textarea {
        width: 100%;
        padding: 0.95rem;
        border-bottom: 1px solid var(--color-darkblue)!important;
        border: none;
        // border-radius: 7px;
        background-color: transparent;
        color: black!important;
  
        font-family: var(--font-alt);
        // color: var(--secondary-color);
        outline: none;
      }
  
      textarea {
        // height: 170px;
      }
  
      &:hover {
        // box-shadow: 0 0 25px var(--primary-color);
      }
    }
  
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 1rem 0;
    }
  }
  