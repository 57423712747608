.app__stories-header{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 3rem 0 2rem 0;
    h1{
        font-size: 34px;
        font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
        &::after{
            border-bottom: 4px solid var(--color-black);
            bottom: -8px;
            content: '';
            left: 50%;
            position: absolute;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            width: 5%;
        }
    }
}

.app__stories-bg{
    width: 100%;
    height: 60vh;
    position: relative;
    background-image: url('../../../../../assets/photos/achieve7.jpg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    position: relative; 
    -webkit-box-shadow: inset 0 0 10px 10px #000;    
      -moz-box-shadow: inset 0 0 10px 10px #000;
      box-shadow: inset 0 0 200px 300px rgba(0, 0, 0, 0.3);

      .app__bg-content{
        
        position: absolute;
        color: #fff;
        top: 2rem;
        left: 1rem;
    }
    h1{
        font-family: var(--font-alt);
        font-size: 28px;
    }
    div .one{
        width: 90%;
        margin: 1rem 0;
        border-bottom: 1px solid var(--color-white);
    }
    p{
        font-family: var(--font-alt);
        font-size: 16px;
        letter-spacing: .1px;
        font-weight: 500;
    }

}


// Gallery 

