.app__cart-chosene{
    // max-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0rem auto 0;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2,1fr);

    .one{
        font-size: 1px;
        width: 100%;
    }
}


.app__detaile-paymn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    
    h1{
        font-family: var(---font-alt);
        font-size: 39px;
        line-height: auto;
        color: #fff;
        color: #000;
        margin-bottom: 0;
        font-weight: normal;
        padding-right: 2rem;

        @media screen and (max-width: 650px) {
            font-size: 30px;
        }
        @media screen and (max-width: 550px) {
            font-size: 24px;
        }
    }
}

.app__contri-infp{
    width: 100%;
    // max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    // margin: 0 auto;
    // background-color: rgba(8, 8, 34, .7);
    padding: 2rem;


    p{
        width: 100%;
        font-size: 45px;
        line-height: 55px;
        font-family: var(--font-best);
        font-weight: normal;
        margin: 1rem 0;
        color: #fff;
        color: #000;

        @media screen and (max-width: 650px) {
            font-size: 33px;
            line-height: 40px;
        }
        @media screen and (max-width: 550px) {
            font-size: 28px;
            line-height: 36px;
        }
    }
}

.choo-amoutn-now{
    line-height: 26px;
    p{
        font-size: 18px;
        line-height: 25px;
    }
}