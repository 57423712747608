.bd-contme{
  padding-top: 5.7rem;
  background-color: rgba(238,238,238);
  // margin-bottom: 5.7rem;
  // height: 20vh;
  @media screen and (max-width: 650px) {
    padding-top: 4.7rem;
  }
    
}

.app__contact-finaldraft{
  background-color: rgba(238,238,238);
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1{
    font-size: 66px;
    font-family: var(--font-best);
    color: var(--color-darkblue);
    padding-top: 6rem;

    @media screen and (max-width: 650px) {
      font-size: 40px;
    }
  }
  .cont-line-me{
    height: 10px;
    width: 70px;
    border-bottom: 3px solid #00c3ff;
    margin-bottom: 2rem;
  }

  p{
    font-size: 20px;
    font-family: var(--font-best);
    text-align: center;
    max-width: 850px;
    padding: 1rem 1rem;
    letter-spacing: 1px;
    line-height: 40px;
    color: var(--color-darkblue);
    font-weight: 100;
    padding-bottom: 3rem;

    @media screen and (max-width: 650px) {
      font-size: 17px;
      line-height: 35px;
    }
  }

 

}

.app__contact-form-bg{
  width: 100%;
  height: 40vh;
  background-color: rgba(238,238,238);

  .app__contanct-me-form{
    width: 80%;
    margin: 0 auto 1rem;
    min-height: 80vh;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    padding: 4rem 4rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    @media screen and (max-width: 950px) {
      width: 90%;
    }
    @media screen and (max-width: 550px) {
      width: 100%;
      padding: 0rem 0rem;

    }
    .label-input{
      width: 100%;
      display: flex;
      padding: 2rem 10px;

      @media screen and (max-width:950px) {
        flex-direction: column;
        // margin-bottom: 2rem;
      }

      input{
        color: #777;
        // border: 1px solid var(--color-black);
        width: 100%;
        padding: 1rem 0;
        font-family: var(--font-best);
        font-size: 22px;
        font-weight: 400;
        margin: 1rem 1rem;
        border: none;
        border-bottom: 1px solid #888888;
        &:hover{
          border-bottom: 1px solid #00c3ff;
        }
        @media screen and (max-width: 950px) {
          margin: 0 0;
          font-size: 20px;
        }
      }
      input.input-two{
        @media screen and (max-width: 950px) {
          margin-top: 4rem;
        }
      }
    }
    .label-textarea{
      width: 100%;
      padding: 10px;
      margin-top: 4rem;
      textarea{
        width: 100%;
        border: none;
        border-bottom: 1px solid #888888;
        color: #777;
        font-family: var(--font-best)!important;
        font-size: 19px;
        font-weight: normal;
        &:hover{
          border-bottom: 1px solid #00c3ff;
        }
      }
    }
    
    .app__btn-form{
      
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-top:2rem;

      button{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border-radius: 10px;
        border: 2px solid #9edc1a;
        color: #7eac1c;

        @media screen and (max-width: 650px){
          width: 90%;
          margin: auto;
        }
        svg{

          margin-left: 5px;
          // color: #9edc1a;
          font-size: 20px;
        }

        &:hover{
          background-color: #9edc1a;
          color: #fff;
        }
      }
    }
  }

  .other-ways-connect{
    width: 100%;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;

    h1{
      font-family: var(--font-best);
      font-size: 27px;
      letter-spacing: 1px;
      font-weight: normal;
      text-transform: uppercase;
      color: var(--color-darkblue);
      margin-top: 5rem;

      @media screen and (max-width: 650px) {
        font-size: 20px;
      }

    }
    .app__ways-line{
      width: 40px;
      height: 10px;
      border-bottom: 3px solid #00c3ff;

    }
    .media-me{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: auto;
      @media screen and (max-width: 950px) {
        flex-direction: column;
        // margin-bottom: 3rem;
      }
      .app__contact-s{
        margin: 0 1rem;
        // font-size: 30px;
        margin: 3rem 1rem 0 2rem;
        border-right: 1px solid #777;
        @media screen and (max-width: 950px) {
          border: none;
          // margin: auto;
          margin-bottom: 0rem;

        }
        li{
          margin-bottom: 3rem;
          padding-right: 3rem;
          text-align: center;
          font-size: 29px;
          color: var(--color-darkblue);
          font-weight: 900;
          font-family: var(--font-best);
          font-weight: normal;
          font-weight: normal;
          letter-spacing: 1px;
          height: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media screen and (max-width: 650px) {
            font-size: 23px;
          }
        }
        a{
          margin-top: 0rem;
          font-size: 20px;
          padding-right: 3rem;
          font-family: var(--font-best);
          font-weight: normal;
          color: var(--color-darkblue);
        }
        .social-imgagesc{
          // width: 100%;
          // display: grid!important;
          // grid-template-columns: repeat(5, 1fr);
          // display: flex;
          .one-two-three {
            flex-direction: row;
            display: flex;
            // width: 30px;
          

            a{
              // width: 30px;
              // width: 100%;
              // height: 30px;
              padding-right: 20px;
              img{
                // width: 100%;
                width: 30px;
                height: 30px;
                object-fit: cover;
                border-radius: 100px;

                // height: 100%;
              }
            }

          }

        }
        
      }
      .app__contact-so{
        padding-top: 0;
        // @media screen and (min-width: 950px) {
        //   padding-left: 3rem;
          
        // }
        // @media screen and (min-width: 850px) {
        //   padding-bottom: 3rem;
          
        // }
      }
    }
  }
}

