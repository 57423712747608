.app__regenpro-container-ne{
    width: 90%;
    margin: 2rem auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    @media screen and (max-width: 850px) {
        grid-template-columns: repeat(1, 1fr);
    }

    .app__contene{
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: flex-start;
        // background-color: aquamarine;
        background: #FFFFFF;
        // border: 1px solid #EBEFFA;
        // box-sizing: border-box;
        width: 100%;
        min-height: 450px;

        // @media screen and (max-width: 950px) {
        //     flex-direction: column-reverse;
        //     margin-bottom: 4rem;
        // }


        a{
            width: 100%;
            height: 350px;
            margin-bottom: 2rem;
        }
        .img{
            width: 50%;
            width: 100%;
            height: 350px;
            display: flex;
            margin-left: 0;
            display: flex;

            align-items: flex-start;
            justify-content: flex-start;
            @media screen and (max-width: 850px) {
                height: 400px;
                width: 100%;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .tje-cont{
            // width: 50%;
            // padding-left: 20px;
            width: 100%;
            margin-bottom: 2rem;

            h1{
                font-size: 35px;
                font-family: var(--font-alt);
                color: var(--color-darkblue);
                font-weight: normal;
                line-height: 45px;
                text-transform: capitalize;
                
                @media screen and (min-width: 850px) {
                    max-width: 850px;
                    font-size: 36px;
                    letter-spacing: 1px;
                    line-height: 45px;

                }
                @media screen and (max-width: 550px) {
                   font-size: 30px;
                   line-height: 40px;
                //    text-align: center;
                }
            }

            p{
                width: 100%;
                font-family: var(--font-best);
                font-size: 18px;
                line-height: 33px;
                @media screen and (min-width: 850px) {
                    max-width: 950px;
                    font-size: 17px;
                    // letter-spacing: 1px;
                    line-height: 34px;

                }
                @media screen and (max-width: 550px) {
                    font-size: 16px;
                    line-height: 30px;
                    // text-align: center;
                 }
            }

            // @media screen and (max-width: 950px) {
            //     width: 100%;
            //     padding-left: 0;
            //     padding: 2rem 10px;
            // }
        }
    }

}

.regenerative-imp-model-proje{
    width: 90%;
    margin: 4rem auto 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    p{
        font-size: 18px;
        font-family: var(--font-best);
        line-height: 33px;
    }
    ol{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 1rem;
        margin-top: 1rem;


        li{
            list-style: decimal;
            font-family: var(--font-best);
            // color: var(--color-darkblue);
            font-size: 19px;
            line-height: 39px;

        }
    }
}
.app__renere-headme{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3rem auto 2rem;

    h1{
        font-size: 45px;
        font-family: var(--font-alt);
        font-weight: normal;
    }
    .app__linert{
        width: 50px;
        height: 10px;
        border-bottom: 3px solid var(--color-black);
    }
}

.app__photo-sto-regepro{
    width: 90%;
    margin: 3rem auto 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2px;

    @media screen and (max-width: 850px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
    }
    @media screen and (max-width: 450px) {
        grid-template-columns: repeat(3, 1fr);
    }

    .one{
        width: 100%;
        height: 250px;
        @media screen and (max-width: 450px) {
            height: 160px;
         }

        img{
            width: 100%;
            height: 250px;
            object-fit: cover;
            @media screen and (max-width: 450px) {
               height: 160px;
            }
        
        }
    }
}