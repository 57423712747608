.app__prodprocesss{
    font-size: 28px;
    color: rgb(9, 9, 156);
    color: var(--color-darkblue);
    margin: 0;
    padding: 0;

    @media screen and (max-width: 550px) {
        font-size: 20px;
    }
    &:hover{
            color: #fff;
    }
}

.app__conent-proreoeoru{
    display: flex;
    align-items: center;
    justify-content: center;
}