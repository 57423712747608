.app__achieve-header{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 3rem 0 1rem 0;
    h1{
        font-size: 34px;
        font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
        &::after{
            border-bottom: 4px solid var(--color-black);
            bottom: -8px;
            content: '';
            left: 50%;
            position: absolute;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            width: 5%;
        }
    }
}

.achieve-now{
    width: 90%;
    // padding-left: 2rem;
    display: flex;
    flex-direction: column;
    margin: auto;
    p{
        font-family: var(--font-best);
        color: var(--color-darkblue);
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        padding-top: 1rem;


    }

}
.app__achieve-body{
    width: 100%;
    min-height: 60vh;
    // background-color: var(--color-darkgreen);
    background-color: #3c862d;
    background-image: linear-gradient(90deg,#356704,#3c862d);
    margin: 2rem 0;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4,1fr);
    @media screen and (max-width: 1150px) {
        grid-template-columns: repeat(3,1fr);
        // height: auto;
    }
    @media screen and (max-width: 850px) {
        grid-template-columns: repeat(2,1fr);
        // height: auto;
    }
    @media screen and (max-width: 650px) {
        grid-template-columns: repeat(1,1fr);
        // height: auto;
    }

    .achive-contain{
        width: 80%;
        padding-left: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-left: 3rem;
        padding: 2rem;
        margin: auto;
        svg{
            color: #fff;
            font-size: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img{
            width: 50px;
            height: 50px;
            background: #fff;
            border-radius: 100px;
            object-fit: cover;
        }
        h1{
            width: 100%;
            font-size: 19px;
            font-family: var(--font-alt);
            color: aliceblue;
            text-align: center;
        }
        p{
            width: 100%;
            font-size: 18px;
            font-family: var(--font-best);
            color: #fff;
            text-align: center;
            // letter-spacing: 1px;
        }
    }
}
.achieve-btn{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 1rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    button.one{
        // font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
        text-transform: uppercase;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 3px;
        line-height: 1.5;
        background: var(--color-black);
        color: #fff;
        cursor: pointer;
        border-radius: 5px;        
        padding: .8rem 1rem;
        margin-right: 1rem;
        font-family: var(--font-best);
        &:hover{
            color: #000;
            background: transparent;
            border: 1px solid var(--color-darkblue);
            transition: .5s ease-in-out;
        }
    }
    button.two{
        // font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
        text-transform: uppercase;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 1.5;
        color: #000;
        cursor: pointer;
        border-radius: 5px;        
        padding: .8rem 1rem;
        margin-right: 1rem;
        font-family: var(--font-best);
        &:hover{
            color: #fff;
            transition: .5s ease-in-out;
            background-color: #000;
        }
    }
}