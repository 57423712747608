
.social-media-accounts{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 0 1rem;

    h1{
        font-family: var(--font-best);
        // font-size: 22px!important;
        font-weight: normal;
        // font-style: italic;
        font-size: 22px;
        letter-spacing: 3px;
        text-transform: uppercase;
        // strong{
        //     color: var(--color-black);
        // }
    }
    .social-imgages{
        width: 100%;
        display: flex;
        margin: 5px auto;
        align-items: center;
        justify-content: center;
        
        
        .one-two-three{
            width: 33px;
            height: 33px;
            display: flex;
            align-items: center;
            justify-content: center;
     
            
            
            img{
                width: 37px;
                height: 37px;
                padding: 5px;
                object-fit: cover;
                // background-color: antiquewhite;
                border-radius: 50%;
                margin: 3px;
                cursor: pointer;
                @media screen and (min-width:2000px) {
                //     width: 43;
                //     height: 43px;
                }
                &:hover{

                    box-shadow: 0 0 30px rgba(0,0,0,0.1);
                }
            }
        }
    }
}