.app__head-inspire{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;


    h1{
        font-family: var(--font-alt);
        font-weight: normal;
        font-size: 45px;
        @media screen and (max-width: 550px) {
            font-size: 30px;
            text-align: center;
        }
    }
    .inspire_line{
        width: 90px;
        height: 10px;
        border-bottom: 3px solid var(--color-black);
        @media screen and (max-width: 550px) {
           width: 60px;
        }
    }
}

.app__content-inspire-wrp{
    width: 100%;
    margin: 3rem auto 0;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1px;

    @media screen and (max-width: 850px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }

        
    .content-inspire * ,
    .content-inspire *:before ,
    .content-inspire *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    } 
    .content-inspire{
        // -webkit-border-radius: 3px;
        // -moz-border-radius: 3px;
        // border-radius: 3px;
        // height: 200px;
        min-height: 200px;
        // margin: 20px auto;
        width: 320px;
        width: 100%;
        overflow: hidden;
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        position: relative;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        .headerrr{
            min-height: 300px;
            img{
                height: 240px;
                display: block;
                // margin: 10px auto;
                // width: 120px;
                width: 100%;
                // height: 120px;
                object-fit: cover;
            
            }
            h1{
                color: #fff;
            }
        }
     

        &:hover {
            height: auto;
            .contnt{
                opacity: 1;
            }
            .headerrr{
                min-height: 400px;
                img{
                    height: 340px;
                
                }
                h1{
                    color: #fff;
                }
            }
            .profile-social-links{
                width: 160px;
                opacity: 1;
            }
        }

        .headerrr{
            background: #ff7043;
            // background-color: #170347;
            background-color: rgb(78, 14, 78);
            // background-color: var(--color-darkgreen);
            // padding: 1px;
            height: 8px;
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
    
            h1{
                font-family: "Open Sans";
                font-family: var(--font-best);
                // color: #170347;
                font-weight: 400;
                // letter-spacing: 1px;
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;
                text-align: center;
                line-height: 1;
                -webkit-transition: all 0.5s;
                -moz-transition: all 0.5s;
                -o-transition: all 0.5s;
                transition: all 0.5s;
                padding-top: 20px;
                @media screen and (max-width: 550px) {
                    font-size: 17px;
                    line-height: 25px;

                }
            
            }
        }
        
        
        .contnt{
    
            padding: 20px;
            position: relative;
            opacity: 1;
            -webkit-transition: all 0.3s 0.25s;
            -moz-transition: all 0.3s 0.25s;
            -o-transition: all 0.3s 0.25s;
            transition: all 0.3s 0.25s;
                &:hover{
                    opacity: 1;
                }

                p{
                    color: #999;
                    font-size: 14px;
                    // font-style: italic;
                    font-size: 16px;
                    text-align: center;
                    font-family:  "Open Sans";
                    font-family: var(--font-best);
                    color: #000;
                    @media screen and (max-width: 550px) {
                       font-size: 14px;
                    }
                }

        }
        ul{
            display: block;
            list-style-type: none;
            margin: 1rem auto;
            padding: 0;
            position: relative;
            opacity: 1;
            width: 160px;
            display: flex;
            align-items: center;
            justify-content: center;

            li{
                display: block;
                float: left;
                // -webkit-transform: translateY(100px);
                // -moz-transform: translateY(100px);
                // -ms-transform: translateY(100px);
                // -o-transform: translateY(100px);
                // transform: translateY(100px);
                -webkit-transition: all 0.3s cubic-bezier(0, 1.135, 0.73, 1.405) 0.25s;
                -moz-transition: all 0.3s cubic-bezier(0, 1.135, 0.73, 1.405) 0.25s;
                -ms-transition: all 0.3s cubic-bezier(0, 1.135, 0.73, 1.405) 0.25s;
                transition: all 0.3s cubic-bezier(0, 1.135, 0.73, 1.405) 0.25s;
                -webkit-transition-delay: 0.35s;
                -moz-transition-delay: 0.35s;
                -o-transition-delay: 0.35s;
                transition-delay: 0.35s;

                &:hover{
                    -webkit-transform: translateY(0);
                    -moz-transform: translateY(0);
                    -ms-transform: translateY(0);
                    -o-transform: translateY(0);
                    transform: translateY(0);
                }

                a{
                    // background: rgba(255, 112, 67, 0.2);
                    // background-color: rgb(175, 5, 175,.5);
                    border-radius: 50%;
                    display: block;
                    float: left;
                    height: 40px;
                    margin: 0 5px;
                    outline: 0 none;
                    width: 40px;
                    -webkit-transition: all 0.3s;
                    -moz-transition: all 0.3s;
                    -o-transition: all 0.3s;
                    transition: all 0.3s;
                    &:hover{
                        -webkit-transform: scale(1.1);
                        -moz-transform: scale(1.1);
                        -ms-transform: scale(1.1);
                        -o-transform: scale(1.1);
                        transform: scale(1.1);
                    }

                    img {
                        padding: 5px;
                        width: 40px;
                    }
                }
            }
        }
    }
}