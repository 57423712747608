
@keyframes popOut {
    from{
        transform: scale(0);
        opacity: 1;
    }
    to{
        transform: scale(1);
        opacity: 1;
    }
}