.app__navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // justify-content: space-evenly;
  // justify-content: center;
  align-items: center;
  // padding-bottom: 4rem;
  padding: .1rem 1rem;
  // background-color: rgb(238, 238, 238);
  background-color: transparent;
  // background: rgba(255, 255, 255, 0.25);
  // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  // border: 1px solid rgba(255, 255, 255, 0.18);
  position: fixed;
  z-index: 9999!important;
  
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // margin-left: 2rem;
  @media screen and (max-width: 2000px) {
    margin-bottom: 0;

  }
  @media screen and (max-width: 1500px) {
    // margin-left:4rem;
    margin-left: 1rem;

  }

  img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    margin-top: 3px;

    @media screen and (min-width: 2000px) {
      width: 100px;
      height: 100px;
    object-fit: cover;

    }

    @media screen and (max-width: 650px) {
      width: 60px;
      height: 60px;
    object-fit: cover;

    }
    @media screen and (max-width: 460px) {
      width: 50px;
      height: 50px;
      object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;

    }
  
  }
}
.app__navbar-donate-now{
  // width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  color: #fff;
  button{
    // padding: .8rem 2rem;
    background-color: var(--color-darkgreen);
    color: var(--color-white);
    text-transform: capitalize;
    // letter-spacing: 1px;
    font-family: var(--font-best);
    font-size: 13px;
    font-weight: 500;

    &:hover{
      background-color: transparent;
      color: #000;
      text-decoration: underline;
      // letter-spacing: 1px;
      border: 1px solid var(--color-darkgreen);
    }

    @media screen and (max-width: 850px) {
      padding: .8rem 1.5rem;
      // letter-spacing:1px;
      font-size: 10px;
    }
    @media screen and (max-width: 650px) {
      display: none;
    }
  }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  position: relative;

  list-style: none;
  @media screen and (max-width: 2000px) {
    // margin-right: 5rem;
  }

  li {
    margin: 0 1rem;
    cursor: pointer;
    // font-family: var(--font-best);
    font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
    font-weight: 900;
    font-size: 14px;

    flex-direction: column;


    a {
      text-decoration: none;
      // flex-direction: column;
      font-family: var(--font-best);
      height: 100%;

      // text-transform: capitalize;
      font-weight: 500;
      font-size: 16px;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 1px;
      // transition: all 0.3s ease-in-out;

      &:hover {
        border-bottom: 1px solid rgb(78, 2, 78);
        // padding-top: -10px;
        // color: var(--color-algeagreen);
        // color: rgb(255, 123, 0);
        // color: #3CA0E7;
        color: rgb(80, 4, 80);
        // border-radius: 5px;
      }

      @media screen and (max-width: 850px) {
        font-size: 14px;
      }
    }

    &:hover {
      div {
        background: #000;
      }
    }
  }

  @media screen and (max-width: 950px) {
    display: none;
  }
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  margin: 0 1.5rem;


  svg {
    width: 70%;
    height: 70%;
    color: var(--white-color);
  }

  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999;

    padding: 1rem;

    width: 80%;
    height: 100vh;
    
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    background: url('../../assets/portfolio/bgWhite.png');
    background-color: var(--white-color);
    background-size: cover;
    background-repeat: repeat;

    /* top box shadow */
    box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);

    svg {
      width: 20px;
      height: 20px;
      color: var(--color-darkblue);
      color: var(--secondary-color);
      margin: 0.5rem 1rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      li {
        margin: 1rem;
        font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
        font-weight: 500;
        // text-transform: capitalize;
        font-size: 19px;
        transition: all .3s ease-in-out;
        &:hover{
          color: var(--secondary-color);
        }
      }
    }

    @media screen and (min-width: 900px) {
      display: none;
    }
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
}




// Dropdown submenus
// nav.primary-navigation {
//   margin: 0 auto;
//   display: block;
//   padding: 120px 0 0 0;
//   text-align: center;
//   font-size: 16px;
// // }
nav.primary-navigation ul li {

  position: relative;
  list-style: none;
  display: inline-block;
  text-decoration: none;
  text-align: center;
}

nav.primary-navigation ul li ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding-left: 0;
  left: 0rem;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  background: #fff;
}
nav.primary-navigation ul li:hover > ul,
nav.primary-navigation ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
  width: 100%;
  min-width: 250px;
  // min-height:100%;
  height: auto;
  transition: .3s ease-in;
  display: flex;
  flex-direction: column;
  text-align: left;
  // text-align: center;
  padding: 20px 0px;
  padding-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, .1);
  box-shadow: 0px 3px 5px -1px #ccc;
  background-color: rgba(255,255,255,190);;
  // background-color: hwb(0 80% 20%);
  background-color: rgb(252, 252, 252);
  background-color: #f1f1f1;

  border-top: 10px solid rgb(78, 2, 78);
  cursor: pointer;
  // border-radius: 5px;
  z-index: 9999!important;
  &:hover{
    margin-top:1rem;

  }
  @media screen and (max-width: 850px) {
    max-width:160px ;
  }
}
nav.primary-navigation ul li ul li {
  clear: both;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  border-style: none;
  a {
    text-decoration: none;
    // letter-spacing: 1px;
    font-family: var(--font-best);
    font-size: 14px;
    display: flex;
    margin: 0;
    align-items: flex-start;
    justify-content: flex-start;
    text-transform: capitalize;
    line-height: 25px;
    // border-top: 1px solid #ccc;
    border-bottom: 1px solid #cccccccc;
    padding: 5px 10px;
    @media screen and (max-width: 850px) {
      // padding: 5px 0;
    }

    svg{
      font-size: 29px;
    }
    
  }
  // a:hover {
  //   color: #3CA0E7!important;
  // }
}
nav.primary-navigation ul li ul li a:hover {
  // padding-left: 10px;
  // border-left: 2px solid #3ca0e7;
  // background-color: rgb(255, 123, 0);
  background-color: #ffffff;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: none;
  display: flex;
  align-items: flex-start;
  transition: all 0.3s ease;
  color: #000000;
  
}

ul li ul li a {
  transition: all 0.5s ease;

}



// side navbar dropdown;
nav.primary-navigation .menu-small li {

  position: relative;
  list-style: none;
  // display: inline-block;
  // padding: 0 30px;
  text-decoration: none;
  text-align: center;
  // font-family: arvo;
}
nav.primary-navigation .menu-small li {

  position: relative;
  list-style: none;
  // margin: 10px 0rem;
  display: inline-block;
  padding: 0px 0px;
  text-decoration: none;
  text-align: center;
  font-family: var(--font-best);
  font-size: 16px;
  font-weight: 500;
 
  // font-family: arvo;
}
nav.primary-navigation .menu-small li ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  height: auto;
  padding-left: 0;
  left: 0;
  display: none;
  // background: #fff;
}

nav.primary-navigation .menu-small li:hover > ul,
nav.primary-navigation .menu-small li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;

  min-width: 230px;
  text-align: left;
  // padding-left: 10px!important;
  padding-top: 20px;
  box-shadow: 0 0 30px rgba(0,0,0,.1);
  box-shadow: 0px 3px 5px -1px #ccc;
  background-color: #ccc;
  
  
}
nav.primary-navigation .menu-small li ul li {
  clear: both;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content:start;
  text-align: left;
  // margin-bottom: 20px;
  border-style: none;

  a{
    font-size: 14px;
    display: flex;
    margin: 0;
    align-items: flex-start;
    justify-content: flex-start;
    text-transform: capitalize;
    line-height: 25px;
    // border-top: 1px solid #ccc;
    border-bottom: 1px solid #ffffff!important;
    width: 100%;
    padding: 5px 10px;

  }
}
nav.primary-navigation .menu-small li ul li a:hover {
  // padding-left: 10px;
  // border-left: 2px solid #3ca0e7;
  transition: all 0.3s ease;
  background-color: rgb(255, 123, 0);
  background-color: #f1f1f1;
  background-color: #ffffff;
  width: 100%;
  border-bottom: 1px solid #ccc;
  width: 100%;
  padding: 5px 10px;
  height: 100%;
}


.menu-items{
  // width: 100%!important;

  a{
    display: flex;
    flex-direction: row!important;
    align-items: center;
    justify-content: center;
    // padding-bottom: 0;
    // padding-top: 0;
    svg{
      display: inline-block;
      // align-items: center;
      // flex-direction: row;
      // margin-bottom: -4px;
      font-size: 25px;
      width: 100%;
    }
  }

}

.menu-small{
  .menu-items{
    a{
      display: flex;
      svg{
        display: inline-block;
        // align-items: center;
        // flex-direction: row;
        // margin-bottom: -6px;
        margin-left: 5px;
        color: var(--color-darkblue);
        font-size: 10px!important;
      }
    }
  }
}

