
.app__forestry-bg{
    width: 100%;
    height: 76vh;
    background-image: url('../../../assets/forestry.jpg');
    background-position: center;
    background-size: cover;

    @media screen and (max-width: 850px){
        height: 60vh;        
    }
}

.app__forestry-head{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    position: relative;

    h1{
        width: 100%;
        font-size: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
        margin-bottom: 1rem;

        &::after{
            border-bottom: 4px solid var(--color-black);
            bottom: -18px;
            content: '';
            left: 50%;
            position: absolute;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            width: 13%;
        }
    }
}

.app__forestry-fixedbg{
    width: 100%;
    background-image: url('../../../assets/bushclearing.jpg');
    height: 60vh;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    box-shadow: 0px 0px 1000px rgba(0,0,0,.8) inset;

    p{
        color: #fff;
    }
}

.app__forestry-conteiner{
   display: flex;
   flex-direction: column;
   width: 100%;
   align-items: center;
   justify-content: center;
   h1{
    font-size: 21px;
    font-family: var(--font-alt);
    letter-spacing: 1px;
    color: var(--color-darkgreen);
    @media screen and (max-width: 650px) {
        width: 90%;
    }


   }

   .forestry-wrap{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    @media screen and (max-width: 850px) {
        flex-direction: column;
    }

    .forestry-image{
        width: 50%;
        // box-shadow: 0px 0px 30px rgba(0,0,0,.1);
        margin: 1rem;
        @media screen and (max-width: 850px) {
            width: 100%;
        }

        img{
            width: 100%;
            height: 350px;
            object-fit: cover;
        }
    }
    .forestry-par{
        width: 50%;
        // box-shadow: 0px 0px 30px rgba(0,0,0,.1);
        margin: 1rem;
        @media screen and (max-width: 850px) {
            width: 100%;
        }
        p{
            font-size: 16px;
            font-family: var(--font-alt);
            letter-spacing: 1px;
        }
    }
   }
   .forestry-intergration{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    h1{
        @media screen and (max-width: 850px) {
            width: 90%;
        }
    }
    p{
        width: 90%;
        font-size: 17px;
        font-family: var(--font-alt);
    }
    .intergration-img{
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        img{
            width: 90%;
        }
    }
    div.benefit{
        margin-top: 2rem;
        @media screen and (max-width: 850px) {
            width: 90%;
        }
    }
    .benefits-p{
        width: 90%;
        font-size: 22px;
        font-weight: 900;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 2rem;
        padding: 1rem 0;
    }
    img{
        width: 90%;
    }
   }
}
