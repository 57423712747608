.app__newprogress-head{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;

    h1{
        font-family: var(--font-alt);
        font-size: 39px;
        font-weight: normal;
    }

    .progreess_line{

        width: 50px;
        height: 10px;
        border-bottom: 3px solid var(--color-black);
    }
}

.app__instr{
    width: 90%;
    margin-top: 3rem;
    margin-bottom: 0px;
    
    @media screen and (max-width: 1150px) {
        width: 90%;
        
    }
    @media screen and (max-width: 850px) {
        width: 100%;
        padding: 0 1rem;

      
        
    }
    p{
        font-size: 15px;
        // font-style: italic;
        font-family: var(--font-best);
    }
}
.app__newprogrees_container{
    width: 90%;
    margin: 2rem auto 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;

    @media screen and (max-width: 1150px) {
        width: 90%;
        
    }
    @media screen and (max-width: 850px) {
        width: 100%;
        padding: 0 1rem;
        grid-template-columns: repeat(1, 1fr);
        
    }

    .card{
        width: 100%;
        min-height: 350px;
        background: #FFFFFF;
        background-color: rgb(78, 9, 78);
        // border: 1px solid #EBEFFA;
        box-sizing: border-box;
        box-shadow: 0px 10px 20px rgb(0 58 208 / 5%);
        // border-radius: 15px;
        // margin: 1rem auto;
        cursor: pointer;
        transition: all .5s cubic-bezier(.8,.5,.2,1.4);
        overflow: hidden;
        position: relative;

        @media screen and (max-width: 950px) {
            width: 100%;
        }

        &:hover{
            box-shadow: 0px 0px 30px rgb(0, 0, 0, 0.3);  
            box-shadow: 0px 10px 20px rgb(0 58 208 / 5%);
            transition: all .5s cubic-bezier(.8,.5,.2,1.4);  
            transform: scale(.97);
            
            .app__img-cont, h1{

                img{
                    transition: all .5s cubic-bezier(.8,.5,.2,1.4);
                    transform: scale(1.6) rotate(20deg);
                    filter: blur(5px);
                }
            }
            .content{
                left:0px;
                transition: all .7s ease-in-out;
                clip-path: circle(75%);
            }
        }

        .app__img-cont{
            width: 100%;
            height: 350px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                // border-top-left-radius: 15px;
                // border-top-right-radius: 15px;
                transition: all .5s cubic-bezier(.8,.5,.2,1.4);

            }
        }
        h1{
            font-size: 19px;
            font-family: var(--font-best);
            font-weight: normal;
            // margin-bottom: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px;
            color: var(--color-darkblue);
            color: #FFFFFF;
            transition: all .5s cubic-bezier(.8,.5,.2,1.4);
            background-color: rgb(78, 9, 78);
            @media screen and (max-width: 550px) {
                font-size: 17px;
                text-align: center;
            }
        }
        .content{
            // width: 100%;
            display: flex;
            top:0px;
            left:0px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            background-color: rgba(255,255,255,.4);
            background-color: rgba(137, 196, 244, .7);
            background-color: rgb(5, 5, 41,.7);
            width:100%;
            height:100%;
            transition: all .7s ease-in-out;
            padding: 20px;
            box-sizing: border-box;
            clip-path: circle(0% at 100% 100%);
            p{
                font-size: 22px;
                font-family: var(--font-best);
                // color: var(--color-darkblue);
                color: #EBEFFA;
                margin: auto;
                text-align: center;
                padding: 0 3px;
                @media screen and (max-width: 550px) {
                    font-size: 16px;
                    text-align: center;
                }
            }

            .btn{
                width: 100%;
                // margin-top: 10px;
                display: flex;
                // align-items: center;
                justify-content: center;
                padding-bottom: 15px;
                
                button{
                    
                    width: fit-content;
                    width: 80%;
                    height: 40px;
                    cursor: pointer;
                    border-style: none;
                    // background-color: #ff3838;
                    // background-color: var(--color-algeagreen);
                    font-size: 15px;
                    outline: none;
                    // box-shadow: 0px 2px 3px rgba(0,0,0,.4);
                    background-color: rgb(75, 15, 75);
                    transition: all .5s ease-in-out;
                    a{
                        color: white;
                    }

                    &:hover{
                        transform: scale(.95) translateX(-5px);
                        transition: all .5s ease-in-out;
                    }
                }
            }
            
        }
    }

}


// .app__newprogress-btns{
//     width: 100%;
//     margin-top: 1rem;
//     display: flex;
//     align-items: flex-start;
//     padding-left: 3rem;
//     justify-content: flex-start;
//     @media screen and (max-width: 650px) {
//         flex-direction: column;
//         margin-top: 1rem;
//         width: 100%;
//     }

//     button{
//         margin: 1rem 1.5rem;
//         padding: 2rem 1.5rem;
//         font-family: var(--font-alt);
//         // width: 90%;
//         min-width: 46.55%;
//         height: 50px;
//         background-color: rgb(75, 3, 75);
//         border-radius: 4px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         a{
//             color: #FFFFFF;
//         }
    
//         @media screen and (max-width: 650px) {
//             width: 90%;
//             padding: 1rem 0rem;
//         }
//     }
//     button.twoo{
    
//         a{
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             font-size: 19px;
//             font-family: var(--font-best);
//             text-transform: uppercase;
            
//             svg{
//                 font-size: 20px;
//                 margin-left: 5px;
//                 display: flex;

//             }
//         }
//         @media screen and (max-width: 650px) {
//             margin-bottom: 2rem;
//         }
//     }
// }