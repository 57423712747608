.app__theteam-header{
    width: 100%;
    min-height: 60vh;
    position: relative;
    padding-top: 5.7rem;
    margin-bottom: 5.7rem;
    @media screen and (max-width: 650px) {
        padding-top: 4.6rem;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        
    }
    .app__flier-team{
        background-color: #13666677;
        position: absolute;
        top:calc(40% - 1px);
        left: 1rem;
        right: 10px;
        max-width: 650px;
        padding: 1.5rem;
        border-radius: 15px;
        h1{
            font-family: var(--font-alt);
            font-size: 45px;
            line-height: 53px;
            margin-bottom: 0;
            color: #fff;
            span{
                color: brown;
            }
            @media screen and (max-width:650px) {
                font-size:30px;
                line-height: 35px;
                
            }

        }
        p{
            font-family: var(--font-alt);
            font-size: 18px;
            color: #fff;
            @media screen and (max-width:650px) {
                font-size:14px;
                line-height: 20px;
                
            }
        }
    }
}

// .app__img-bghistrwe{
//     h1{
//         font-size: 1px;
//     }
// }

.app__theteam-intro{
    width: 100%;
    margin: 3rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // margin: auto;

    h1{
        font-family: var(--font-alt);
        font-weight: normal;
        margin: auto;
        max-width: 500px;
        text-align: center;
        line-height: 56px;
        text-transform: capitalize;
        font-size: 45px;

        @media screen and (max-width: 550px) {
            font-size: 33px;
            line-height: 43px;
        }
    }
    .linernrn{
        width: 50px;
        height: 10px;
        border-bottom: 3px solid var(--color-black);
        
        @media screen and (max-width: 550px) {
            width: 40px;
        }
    }
    p{
        font-family: var(--font-alt);
        padding: 1rem 0;
        margin: auto;
        max-width: 650px;
        text-align: center;
        line-height: 23px;
        font-size: 16px;
        @media screen and (max-width: 650px) {
        padding: 1rem 5px 1rem 5px;

        }
    }
}

.app__theteam-profiles{
    width: 100%;
    // margin: auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    // box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    // gap: 10px;
    margin: 0rem auto;
    // margin-bottom: 1rem;
    @media screen and (min-width:2000px) {
        grid-template-columns: repeat(4,1fr);
        margin: 3rem auto;
    }
    @media screen and (max-width:850px) {
        grid-template-columns: repeat(2,1fr);
        
    }
    @media screen and (max-width:650px) {
        grid-template-columns: repeat(1,1fr);
        margin: auto;
        // margin: 1rem auto;
    }
    @media screen and (max-width:550px) {
        grid-template-columns: repeat(1,1fr);
        margin: auto;
        // margin: 1rem auto;
    }

    .app__theteam-cards{
        width: 100%;
        height: 450px;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        // min-height: 100%;
        // background-color: var(--white-color);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: start;
        // padding: 2rem;
        position: relative;
        margin: auto;
        // border-radius: 3px;
        // margin-bottom: 1rem;
        cursor: pointer;
        transition: all .5s cubic-bezier(.8,.5,.2,1.4);
        overflow: hidden;
        position: relative;
        
        &:hover{
            box-shadow: 0px 10px 20px rgb(0 58 208 / 5%);
            transition: all .5s cubic-bezier(.8,.5,.2,1.4);
            // box-shadow: 0px 2px 3px rgba(0,0,0,.3);
            transform: scale(.97);
            .app__conetint{
                left:0px;
                transition: all .7s ease-in-out;
                clip-path: circle(75%);
            }

            .head-imgme{
                transition: all .5s cubic-bezier(.8,.5,.2,1.4);
                transform: scale(1.6) rotate(20deg);
                filter: blur(5px);
            }
        }

        .head-imgme{
            width: 100%;
            height: 450px;
            object-fit: cover;
            transition: all .5s cubic-bezier(.8,.5,.2,1.4);

            @media screen and (min-width: 2000px) {
                height: 400px;
            }

        }

        .app__conetint{
            width: 100%;
            position: absolute;
            top:0px;
            left:0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // background-color: rgba(255,255,255,.4);
            background-color: rgb(11, 11, 61, .7);
            margin: 0!important;
            width:100%;
            height:100%;
            transition: all .7s ease-in-out;
            padding: 20px;
            box-sizing: border-box;
            clip-path: circle(0% at 100% 100%);
            font-size: 18px;
            color: var(--color-black);
            font-family: var(--font-best);
        }
        .theteam-headtext1{
            font-family: var(--font-alt);
            font-size: 16px;
            color: #fff;
            // padding-left: 1rem;
            padding: 10px;
            margin-bottom: 10px;
            // display: flex;
            // align-items: center;
            // margin: auto;
        }
        .theteam-headtext2{
            font-family: var(--font-alt);
            font-size: 14px;
            font-weight: 500;
            color: #777;
            padding-left: 1rem;
            margin-bottom: 1rem;
            font-style: italic;
            color: #fff;
            // display: flex;
            // align-items: center;
            // margin: auto;
        }
        .theteam-headtext3{
            font-family: var(--font-alt);
            font-size: 16px;
            font-weight: 500;
            max-width: 500px;
            text-align: center;
            padding-left: 1rem;
            margin-bottom: 1rem;
            color: #fff;
        }
        .aboutcustom-galleryme{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .account-image{
                width: 100%;
                padding-left: 1rem;
                margin-right: 10px;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem 1rem 1rem 1rem;
                // position: absolute;
                // top: 0;

                .imger{
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                    margin-right: 10px;
                    // background-color: aqua;
                    padding: 2px;
                    border-radius: 100px;
                    
                    &:hover{
                        
                        background-color: #fff;
                        box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
    }
}

.ceo-quote{
    width: 100%;
    // background-color: rgb(11, 11, 61);
    // background-color: rgb(0,0,0,.8);
    box-shadow: inset 0 0 100px 400px rgb(0,0,0);
    height: 100%;
    min-height: 30vh;
    position: relative;
    padding: 4rem 0 2rem 4rem;
    @media screen and (max-width:550px) {
        padding-left: 1rem;
     }

    p{
        font-family: var(--font-best);
        color: #fff;
        max-width: 650px;
        font-size: 19px;
        @media screen and (max-width:650px) {
           font-size: 17px;
        }

    }
    .img-content{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 10px;

        .text-containme{
            padding-left: 1rem;
            h2{
                color: #fff;
                font-size: 17px;
                font-family: var(--font-alt);
            }
            h3{
                color: #fff;
                font-family: var(--font-best);
                font-size: 13px;
                font-weight: 400;
                @media screen and (max-width:650px) {
                   font-size: 10px;
                }

            
            }
            
        }
    }
    .hero-img{
        width: 180px;
        height: 180px;
        background-color: #fff;
        border-radius: 100px;
        position: relative;
        cursor: pointer;
        @media screen and (max-width:650px) {
            width: 130px;
            height: 130px;
        }
        img{
            width: 100%;
            height: 100%;
            box-shadow: 0 0 10px rgba(0,0,0,0.3);
            border-radius: 100px;
            object-fit: cover;
        }
    }

}