.app__info-plant{
    // padding-top: 6rem;
    width: 70%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-left: 2rem;
    gap: 0;
    @media screen and (max-width: 650px) {
        width: 100%;
        margin-top: 0!important;
        padding-top: 0;
        margin: 0!important;
    }
    
    .app__info-details{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding-left: 2rem;
        padding: 2rem;

        img{
            width: 60px;
            height: 60px;
            object-fit: cover;
        }
        h1{
            font-family: var(--font-alt);
            font-size: 18px;
            width: 100%;
        }
        div{
            border-bottom: 1px solid #000;
            width: 80%;
            margin: 1rem 0;
        }
        h2{
            width: 100%;
            font-family: var(--font-alt);
            font-size: 20px;
        }
        
    }
}
.app__info-data{
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 1rem auto;

    h1{
        font-family: var(--font-alt);
        font-size: 20px;
        text-transform: uppercase;
    }
    div{
        border-bottom: 1px solid #000;
        width: 70%;
        margin: 1rem 0;
    }

    h2{
        font-family: var(--font-alt);
        font-size: 20px;
        padding-top: 1rem;
    }
    p{
        font-family: var(--font-alt);
        font-size: 16px;
        color: var(--color-darkblue);
        font-weight: 500;
        padding-top: 1rem;

    }
    h3{
        font-family: var(--font-alt);
        font-size: 20px;
        padding-top: 1rem;
    }
    h4{
        width: 90%;
        font-size: 16px;
        font-family: var(--font-alt);
        font-weight: 500;
        padding-top: 1rem;
        color: var(--color-darkblue);
    }
}

.app__infomation-wrapper{
    width: 100%;
    height: auto;
    background-color: var(--color-darkgreen);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    margin: auto;
    padding: 2rem;
    @media screen and (max-width: 950px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
    }

    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 90%;
        padding-top: 3rem;
        @media screen and (max-width: 950px) {
            display: flex;
            align-items: center;
            justify-content: center;
            div{
                width: 40%!important;
            }
        }
        @media screen and (max-width: 650px) {
            display: flex;
            align-items: center;
            justify-content: center;
            div{
                width: 40%!important;
            }
        }
        img{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            object-fit: cover;
        }
        svg{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            font-size: 50px;
            color: #fff;
        }
        div{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80%;
            margin: 1rem 0;
            border-bottom: 1px solid var(--color-white);
            @media screen and (max-width: 650px) {
                width: 40%;
            }
        }
        p{
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: var(--font-alt);
            color: #fff;
            font-size: 16px;

        }
    }
}