.app__photogallery-wrapper {
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // gap: 1px;

    @media screen and (max-width: 850px) {
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }
    @media screen and (max-width: 650px) {
      grid-template-columns: repeat(1, 1fr);
    }
    @media screen and (max-width: 550px) {
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
    }

    .single{
      width: 100%;
      height: 300px;
      cursor: pointer;
      @media screen and (max-width: 550px) {
        height: 200px;
      }

      img{
          width: 100%;
          height: 100%;
          object-fit: cover;

          &:hover{
            transform: scale(1.02);
          }
      }
    }
  }
  
  .app__photogallery-sliderwrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .app__photogallery-sliderwrap .btnClose, .app__photogallery-sliderwrap .btnPrev, .app__photogallery-sliderwrap .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 9999;
    font-size: 25px;
  }
  
  .btnNext:hover, .btnPrev:hover, .btnClose:hover {
    opacity: 1;
  }
  
  .app__photogallery-sliderwrap .btnClose {
    top: 120px;
    right: 40px;
    font-size: 21px;
    background-color: #fff;
    padding: 1px;
    border-radius: 100px;
    color: #000;
  }
  
  .app__photogallery-sliderwrap .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
  }
  
  .app__photogallery-sliderwrap .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
  }
  
  .app__photogallery-fullscreenImage{
    // width: calc(100% - 40px);
    // height: calc(100% - 40px);
    width: calc(60% - 50px);
    height: calc(80% - 30px);
    display: flex;
    margin-top: 4rem;
    align-items: center;
    justify-content: center;
    // background-color: aqua;
    box-sizing: border-box;
    &:hover{
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    }
    @media screen and (max-width: 850px) {
      width: 90%;
      height: 350px;
    }
  }

  
  .app__photogallery-fullscreenImage a {
    // max-width: 100%;
    // max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // pointer-events: none;
    // -webkit-user-select: none; /* Safari */
    // -ms-user-select: none; /* IE 10 and IE 11 */
    // user-select: none; /* Standard syntax */  
  }
  .app__photogallery-fullscreenImage img {
    // max-width: 100%;
    // max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */  
  }