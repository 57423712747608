
.app__vision-header{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   margin-bottom: 3rem;

   h2{
    font-size: 33px;
    font-family: var(--font-alt);
    font-weight: 900;
    font-size: 30px;
    font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
    &::after{
        border-bottom: 4px solid #fc0;
        bottom: -28px;
        content: '';
        left: 50%;
        position: absolute;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 11%;
    }
    @media screen and (max-width: 650px) {
        font-size: 27px;
        
    }
   }
}

.app__vision-container{
    width: 100%;
    display: flex;
    margin: 3rem 1rem 1rem;
    
    @media screen and (max-width: 970px) {
        flex-direction: column-reverse;
    }

    .app__vision-content{
        width: 50%;
        // box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        margin-right: 1rem;
        // margin-left: 1rem;
        @media screen and (max-width: 970px) {
            width: 100%;
        }

        h1{
            font-size: 19px;
            width: 80%;
            font-weight: 900;
            color: var(--color-darkblue);
            font-family: var(--font-alt);
            padding: 2rem 0rem 0rem 1rem;
            @media screen and (max-width: 970px) {
                width: 100%;
                padding-left: 0px;
            }
        }

        p.two{
            font-size: 17px;
            width: 80%;
            letter-spacing: 1px;
            font-weight: 500;
            color: var(--color-darkblue);
            font-family: var(--font-alt);
            padding: 0rem 0rem 0rem 1rem;
            margin-bottom: 1rem;
            span{
                text-transform: capitalize;
            }
            @media screen and (max-width: 970px) {
                width: 100%;
                padding-left: 0;
            }
        }
        p.one{
            
            border-left: 3px solid var(--color-darkblue);
            margin-left: 1rem;
            padding-left: 10px;
            margin-bottom: 1rem;
            width: 80%;
            color: var(--color-darkblue);
            font-family: var(--font-alt);
            font-size: 17px;
            @media screen and (max-width: 970px) {
                width: 100%;
                margin-left: 0;
                // padding-left: 0;

            }

        }
       
    }

    .app__vision-video{
        width: 50%;
        // box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        margin-right: 1rem;
        position: relative;
        height: auto;
         
        @media screen and (max-width: 950px) {
            width: 100%;
        }

        video{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border: none;
            outline: none;
        }

        .app__vision-overlay{
            position: absolute;
            inset: 0;
            background: rgba(0, 0, 0, 0.3);
        }
        .app__vision-overlay_circle{
            &:hover{
                width: 100px;
                height: 100px;
                cursor: pointer;
                // background-color: #fff;
                border-radius: 100px;
                border: 1px solid var(--color-white);
                color: var(--color-gray);
                @media screen and (max-width: 950px) {
                    width: 60px;
                    height: 60px;
                    border-radius: 100%;

                }
                
            }
            svg{
                font-size: 30px;
                color: #fff;
                transition: .3s ease-in;
                &:hover{
                    color: var(--color-gray);
                }
            }
             
            @media screen and (max-width: 950px) {
                width: 50px;
                height: 50px;
                svg{
                    font-size: 20px;
                }
            }
        }
    }
}