.app__progresspage-bg{
    width: 100%;
    height: 100vh;
    position: relative;
    padding-top: 5.7rem;
    margin-bottom: 2.7rem;
    @media screen and (max-width: 650px) {
        padding-top: 4.6rem;
    }
    .overlay{
        position: absolute;
        width: 100%;
        height: 84.8%;
        z-index: 1;
        // inset: 0;
        background: rgba(0,0,0,0.45);
        @media screen and (max-width: 650px) {
            height: 87.8%;
        }

    }

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        // position: relative;
        // position: absolute;
    }
    .cont-container{
        width: 100%;
        max-width: 50%;
        // height: 40%;
        background-color: darkcyan;
        position: absolute;
        bottom: 30%;
        left: 2rem;
        border-radius: 10px;
        padding: 1rem;
        z-index: 2;
        @media screen and (max-width: 650px) {
            max-width: 80%;
            bottom: 20%;
        }
        h1{
            color: #fff;
            width: 100%;
            font-family: var(--font-alt);
        }
        div{
            width: 40%;
            height: 1px;
            border-bottom: 2px solid var(--color-white);
            margin-bottom: 1rem;
            @media screen and (max-width: 650px) {
                width: 100%;
            }
        }
        p{
            color: #fff;
            font-family: var(--font-alt);
        }
    }
}



.app__progress-content-dam-fundung{
    width: 90%;
    // height: 100vh;
    margin:auto;
    // overflow: hidden;

    h1{
        font-size: 16px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        font-family: var(--font-alt);
    }
    div.onme{
        width: 100px;
        height: 1px;
        border-bottom: 2px solid var(--color-black);
        margin-bottom: 1rem;
    }
    p{
        font-family: var(--font-alt);
        font-size: 15px;
        max-width: 750px;
        
    }
    .img-container-progress{
        width: 90%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        margin-bottom: 2rem;
        @media screen and (min-width: 1550px) {
            grid-template-columns: repeat(4, 1fr);
            width: 100%;
        }
        @media screen and (max-width: 850px) {
            grid-template-columns: repeat(2, 1fr);
            width: 100%;
        }
        @media screen and (max-width: 650px) {
            grid-template-columns: repeat(1, 1fr);
            width: 100%;
        }
        // height: 100vh;

        .one{
            width: 100%;
            display: flex;
            flex-direction: column;
            // background-color: var(--color-black);
            box-shadow: 0 0 30px rgba(0,0,0,0.1);
            border-radius: 15px;
            padding: 10px;
            p{
                font-family: var(--font-alt);
                padding:0 5px;
                margin: auto;
            }
            h1{
                font-size: 14px;
                // padding:0 5px;
                margin: auto;

            }
            img{
                width: 100%;
                height: 230px;
                object-fit: cover;
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;
            }
        }

    }
    
    
    
}

.app__fixe-bg-info{
    width: 100%;
    background-image: url('../../../assets/greengrams.jpg');
    height: 70vh;
    background-position:  center;
    background-size: cover;
    background-attachment: fixed;
    -webkit-box-shadow: inset 0 0 100px 10px #000;    
    -moz-box-shadow: inset 0 0 10px 10px #000;
    box-shadow: inset 0 0 400px 100px rgba(0, 0, 0, 0.8);
    padding: 1rem;

    h1{
        font-size: 17px;
        color: #fff;
        font-family: var(--font-alt);
    }
    div.one{
        width: 300px;
        height: 1px;
        border-bottom: 2px solid var(--color-white);
    }
    .app__contetn-wrap-list{
        width: 100%;
        ol{
            width: 100%;
            padding-left: 1rem;
            li{
                list-style:decimal;
                color: #fff;
                font-family: var(--font-alt);
            }
        }
    }
}


.app__progress-content-camp{
    width: 90%;
    margin:auto;
    padding-bottom: 2rem;

    h1{
        font-size: 16px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        font-family: var(--font-alt);
        text-transform: capitalize;
    }
    div{
        width: 100px;
        height: 1px;
        border-bottom: 2px solid var(--color-black);
        margin-bottom: 1rem;
    }

    p{
        font-family: var(--font-alt);
        font-size: 16px;
        // margin-bottom: 2rem;

    }
    ol{
        width: 100%;
        padding-left: 1rem;
        li{
            list-style: decimal;
            font-family: var(--font-alt);
            color: var(--color-darkblue);
        }
    }

}

.btn-content-camo{
    width: 90%;
    padding-bottom: 2rem;
    // margin-bottom: 2rem;
    margin: auto;
    button{
        border-radius: 5px;
        background-color: #000;
        color: #fff;
        transition: .5s ease-in;

        &:hover{
            background-color: var(--color-algeagreen);
        }
    }
}