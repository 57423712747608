
.app__economic-content{
    width: 100%;
    margin: 0rem auto 0rem;
    display: flex;

    @media screen and (max-width: 950px) {
        flex-direction: column-reverse;
    }

    .content-img{
        width: 50%;
        height: 500px;
        display: grid;
        grid-template-columns: repeat(1 ,1fr);
            
        @media screen and (max-width: 950px) {
            width: 100%;
            margin: auto;
        }
            
        @media screen and (max-width: 650px) {
            grid-template-columns: repeat(1,1fr);
            width: 100%;
            margin: auto;
            // height: 250px;
        }
        .one{
            width: 100%;
            height: 500px;
            box-sizing: border-box;
            box-shadow: 0px 10px 20px rgb(0 58 208 / 5%);
            // border-radius: 15px;
            margin: auto;
            cursor: pointer;
            transition: all .5s cubic-bezier(.8,.5,.2,1.4);
            overflow: hidden;
            position: relative;
            @media screen and (max-width: 650px) {
                grid-template-columns: repeat(1,1fr);
                width: 100%;
                margin: auto;
                // height: 200px;
            }

            &:hover{
                box-shadow: 0px 0px 30px rgb(0, 0, 0, 0.3);  
                // box-shadow: 0px 10px 20px rgb(0 58 208 / 5%);
                transition: all .5s cubic-bezier(.8,.5,.2,1.4);  
                transform: scale(.97);
                img{
                    transition: all .5s cubic-bezier(.8,.5,.2,1.4);
                    transform: scale(1.6) rotate(20deg);
                    filter: blur(5px);
                }
                p{
                    left:0px;
                    transition: all .7s ease-in-out;
                    clip-path: circle(75%);
                }
            }
            
            img{
                width: 100%;
                height: 100%;
                height: 500px;
                object-fit: cover;
            }
            p{
                // font-size: 35px;
                // color: var(--color-darkblue);
                // font-family: var(--font-alt);
                // font-weight: 900;
                // text-align: center;
                // padding: 10px;
                // position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                top:0px;
                left:0px;
                background-color: rgba(255,255,255,.4);
                background-color: rgba(13, 5, 48, 0.7);
                font-weight: normal;
                font-family: var(--font-best);
                font-size: 20px;
                line-height: 35px;
                text-align: center;
                color: #fff;
                width:100%;
                height:100%;
                transition: all .7s ease-in-out;
                padding: 20px;
                box-sizing: border-box;
                clip-path: circle(0% at 100% 100%);
                
            }
        }
    }
    .content-p-ecom{
        width: 50%;
        max-width: 560px;
        margin: auto;
        // padding-left: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        @media screen and (max-width: 950px) {
            width: 90%;
            margin-bottom: 2rem;
        }
        @media screen and (max-width: 650px) {
           width: 90%;
           margin: auto;
           padding-left: 2rem;
        }

        h1{
            font-family: var(--font-alt);
            // font-weight: normal;
            font-size: 45px;
            text-transform: capitalize;
            // min-width: 400px;
            width: 100%;
            line-height: 60px;
            margin-bottom: 1rem;

            @media screen and (max-width: 850px) {
                width: 100%;
                padding-left: 1.5rem;

                // margin-bottom: 2rem;
            }

            @media screen and (max-width: 550px) {
                width: 100%;
                font-size: 30px;
                line-height: 40px;
                padding-left: 1.5rem;
                text-align: left;

                // margin-bottom: 2rem;
            }
        }

        p{
            font-family: var(--font-best);
            font-size: 19px;
            line-height: 30px;
            @media screen and (max-width: 850px) {
                font-size: 18px;
                line-height: 30px;
                padding-left: 1.5rem;
    
            }
            @media screen and (max-width: 550px) {
                font-size: 16px;
                line-height: 27px;
                padding-left: 1.5rem;
    
            }
        }
        
        @media screen and (max-width: 850px) {
            padding-left: 0px;
            width: 100%;
            margin: 1rem auto;

        }
        @media screen and (max-width: 650px) {
            width: 100%;
            padding-left: 0px;
            margin: 1rem auto;
        }
    }
}