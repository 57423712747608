.containeerme{
    width: 100%;
    padding-top: 5.7rem;

    @media screen and (max-width: 650px) {
        padding-top: 4.7rem;
    }
}

.thetrees-bgimgme{
    width: 100%;
    height: 70vh;
    transform: rotate(360deg);
    background-image: url('../../../../../assets/community.jpg');
    background-position: center;
    background-size: cover;
    position: relative;
    -webkit-box-shadow: inset 0 0 100px 10px #000;    
    -moz-box-shadow: inset 0 0 10px 10px #000;
    box-shadow: inset 0 0 400px 100px rgba(0, 0, 0, 0.8);
    padding: 3rem;

    .content-bg-conteniner{
        position: absolute;
        width: auto;
        padding: 2rem;
        h1{
            font-family: var(--font-alt);
            font-size: 69px;
            line-height: 70px;
            letter-spacing: 2px;
            color: #fff;
            span{
                color: darkcyan;
            }
        }
        p{
            font-family: var(--font-alt);
            font-size: 18px;
            max-width: 850px;
            color: #fff;
        }
    }

}



.thetreesbg-content{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 2rem auto;
    

    h1{
        font-family: var(--font-alt);
        color: var(--color-darkblue);
        text-transform: uppercase;
        letter-spacing: 2px;

    }
    .-onetome{
        width: 260px;
        height: 10px;
        border-bottom: 3px solid var(--color-darkblue);
    }
    p{
        max-width: 850px;
        text-align: center;
        font-size: 17px;
        font-family: var(--font-alt);
        font-weight: 500;
        text-align: start;
        padding-top: 1rem;
    }
}

.thetrees-head{
    width: 100%;
    font-size: 20px;
    color: var(--color-darkblue);
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    letter-spacing: 2px;
    font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
    position: relative;

    &::after{
        border-bottom: 4px solid var(--color-darkblue);
        bottom: -18px;
        content: '';
        left: 50%;
        position: absolute;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 7%;
    }
}

.thetrees-content{
    width: 90%;
    margin:2rem auto;
 
    p{
        max-width: 1150px;
        padding-top: 1rem;
        padding-right: 10px;
        font-size: 18px;
        font-family: var(--font-alt);
        font-weight: 500;
        text-align: start;
    }
}


.app__thetrees-containerimg{
    width: 90%;
    display: grid;
    padding-top: 2rem;
    margin: auto;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    @media screen and (max-width: 850px) {
        grid-template-columns: repeat(2, 1fr);   
    }
    @media screen and (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);   
    }

    .thetrees-data{
        width: 100%;
        box-shadow: 0px 0px 30px rgba(0,0,0,.1);
        // padding: 10px;
        // gap: 1rem;
        // margin: 2rem auto;
        border-radius: 15px;

        img{
            width: 100%;
            height: 250px;
            object-fit: cover;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            box-shadow: 0px 0px 30px rgba(0,0,0,.1)inset(0);
            &:hover{
                padding: 10px;
                transition: .5s ease-in-out;

            }
        }
        .one{
            padding: 10px;
            h1{
                font-family: var(--font-alt);
                font-size: 15px;
                letter-spacing: 1px;
                font-weight: 900;
                text-transform: uppercase;
                text-align: center;
            }
            p{
                font-family: var(--font-alt);
                font-size: 17px;
                font-weight: 500;
                text-align: center;
            }
        }
    }
}

.the-trees-btn-n{
    width: 90%;
    margin: 2rem auto;

    button{
        border-radius: 5px;
    }
}