.app__collab-headr{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem auto 2rem;
}

.app__collab-container{
    width: 100%;
    margin: 0rem auto 0rem;
    display: flex;
    flex-direction: row-reverse;

    @media screen and (max-width: 950px) {
        flex-direction: column;
    }

    .app__collab-content{
        width: 50%;
        max-width: 550px;
        margin: auto;
        padding-right: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 950px) {
            padding-right: 0px;
            
        }
        h1{
            font-size: 50px;
            font-family: var(--font-alt);
            // font-weight: normal;
            text-transform: capitalize;
            line-height: 65px;
            @media screen and (max-width: 950px) {
                text-align: center;
            }
            @media screen and (max-width: 550px) {
                font-size: 30px;
                line-height: 40px;
                text-align: left;
            }
        }

        ol{
            width: 100%;
            padding-left: 1rem;
            @media screen and (max-width: 550px) {
                margin-bottom: 10px;
            }
            li{
                font-size: 18px;
                font-family: var(--font-best);
                list-style-type: decimal;
                @media screen and (max-width: 550px) {
                    font-size: 16px;
                    line-height: 25px;
                }
                // display: flex;
                svg{
                    margin-right: 15px;
                    font-size: 20px;
                }

                @media screen and (min-width: 950px) {
                    max-width: 500px;
                }
            }
        }
        
        @media screen and (max-width: 950px) {
            width: 90%;
        }

    }

    .app__collab-imgcontent{
        width: 50%;
        // padding-left: 1rem;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        // gap: 10px;

        @media screen and (max-width: 950px) {
            width: 90%;
            width: 100%;
            padding-left: 0rem;
            // margin: 2rem auto;

        }
        @media screen and (max-width: 950px) {
            grid-template-columns: repeat(1, 1fr);

        }

        .one{
            width: 100%;
            height: 500px;
            box-sizing: border-box;
            box-shadow: 0px 10px 20px rgb(0 58 208 / 5%);
            // border-radius: 15px;
            // margin: 1rem auto;
            cursor: pointer;
            transition: all .5s cubic-bezier(.8,.5,.2,1.4);
            overflow: hidden;
            position: relative;
            &:hover{
                box-shadow: 0px 0px 30px rgb(0, 0, 0, 0.3);  
                // box-shadow: 0px 10px 20px rgb(0 58 208 / 5%);
                transition: all .5s cubic-bezier(.8,.5,.2,1.4);  
                transform: scale(.97);

                img{
                    transition: all .5s cubic-bezier(.8,.5,.2,1.4);
                    transform: scale(1.6) rotate(20deg);
                    filter: blur(5px);
                }
                p{
                    left:0px;
                    transition: all .7s ease-in-out;
                    clip-path: circle(75%);
                }

            }


            img{
                width: 100%;
                height: 500px;
                object-fit: cover;
            }

            p{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                top:0px;
                left:0px;
                background-color: rgba(255,255,255,.4);
                background-color: rgba(5, 2, 26, 0.7);
                width:100%;
                height:100%;
                transition: all .7s ease-in-out;
                padding: 20px;
                box-sizing: border-box;
                clip-path: circle(0% at 100% 100%);
                font-size: 26px;
                line-height: 34px;
                font-family: var(--font-best);
                color: var(--color-darkblue);
                color: #EBEFFA;
                margin: auto;
                text-align: center;
                padding: 0 3px;

            }
        }
    }

}