.app__video-headme{
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1{
    font-family: var(--font-alt);
    font-size: 50px;
  }
  div{
    width: 10%;
    height: 1px;
    border-bottom: 3px solid var(--color-black);
    margin-bottom: 1rem;
  }
}
.app__content-info_video{
  width: 90%;
  font-size: 20px;
  font-family: var(--font-best);
  line-height: 35px;
  // max-width: 950px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 850px) {
    font-size: 18px;
    width: 100%;
    line-height: 28px;
  }
}
.app__content-container_video{
    width: 100%;
    // height: 100vh;
    height: 90vh;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // justify-content: flex-start;
    // margin-bottom: 5rem;
    position: relative;
     
    // .btn-now{
    //   background-color:  rgb(11, 11, 61);
    //   padding:.5rem 1rem;
    //   margin: 3rem 0 2rem 0;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   border-radius: 3px;
    //   transition: .3s ease-in-out;
    //   width: 180px;
    //   // position: absolute;
    //   top: 0;
    //   // width: 950px;
    //   &:hover{
    //     background-color: #000;
    //     transition: .3s ease-in-out;
    //   }
    //   a{
    //     color: black;
    //     display: flex;
    //     align-items: flex-start;
    //   }

    //   h2{
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     color: #fff;
    //     font-family: var(--font-alt);
    //     font-size: 16px;
    //     // max-width: 650px;
    //     cursor: pointer;

    //     svg{
    //       display: flex;
    //       align-items: center;
    //       margin: auto;
    //       font-size: 20px;
    //     }
    //   }
    // }
    @media screen and (min-width: 1150px) {
      min-width: 1200px;
    }
    iframe{
        position: absolute;
        top: 10;
        width : 100%;
        height: 100%;
        @media screen and(max-width: 950px) {
          //  height: 599px;
            
        }
        @media screen and(max-width: 650px) {
           height: 499px;
            
        }
    
    }
    
}


