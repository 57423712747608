.app__photo-camp-stories{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    h1{
        font-size: 38px;
        font-family: var(--font-alt);
        text-transform: capitalize;
    }
    div{
        width: 200px;
        height: 1px;
        border-bottom: 2px solid var(--color-black);
    }
}
// .one{
//     width: 100%;
//     font-family: var(--font-alt);
//     padding-left: 1rem;
// }

.slide-me-courasel{
    width: 100%;
    // height: 80vh;
    margin: auto;
    background-color: aliceblue;
    background-image: url('../../../../assets/child4.jpg');
    background-size: cover;
    background-position: center;
    padding: 2rem;
    align-items: center;

    .box-one-item{
        width: 95%;
        background-color: aquamarine;
        box-shadow: 0 0 30px rgba(0,0,0,.1);
        // border-radius: 10px;
        background-color: white;
        padding: 6px;
        .img{
            inset: 0;
            box-shadow: 0 0 30;
        }

        img{
            width: 100%;
            height: 250px;
            object-fit: cover;
            padding: 5px;
            @media screen and (min-width: 1150px) {
                height: 270px;
            }
            @media screen and (min-width: 1500px) {
                height: 350px;
            }
            @media screen and (max-width: 1150px) {
                height: 250px;
            }
            @media screen and (max-width: 950px) {
                height: 200px;
            }
            @media screen and (max-width: 650px) {
                height: 150px;
            }
        }
        p{
            font-size: 16px;
            font-family: var(--font-alt);
            padding-left: 10px;
        }
    }

}
