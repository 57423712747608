.app__slickslider-tress{
  width: 100%;
  padding-top: 5.7rem;

  @media screen and (max-width: 650px) {
    padding-top: 4.7rem;
  }


  .img{
    width: 100%;
    height: 85vh;
    @media screen and (max-width: 850px) {
      height: 70vh;
    }
    @media screen and (max-width: 450px) {
      height: 55vh;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}