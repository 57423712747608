.app__thecrops{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 4rem 0;
    h1{
        width: 100%;
        font-size: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: var(--font-alt);
        font-weight: normal;

        @media screen and (max-width: 650px) {
            font-size: 26px;
        }
    }
    .app__teress-crops{
        width: 100px;
        height: 10px;
        border-bottom: 3px solid var(--color-black);
        @media screen and (max-width: 650px) {
            width: 80px;
        }
    }
}

.app__thecrops-p{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
        width: 90%;
        font-family: var(--font-alt);
        font-size: 17px;
    }
}

.app__thecrops-wrapper{
    width: 98%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin: auto;
    @media  screen and (max-width:1150px) {
        // padding: 1rem;
        width: 90%;
        
    }
    // @media  screen and (max-width:1150px) {
    //     grid-template-columns: repeat(2, 1fr);
        
    // }
    @media  screen and (max-width: 650px) {
        grid-template-columns: repeat(1, 1fr);
        
    }

    .thecrops-data{
        box-shadow: 0px 0px 10px rgba(0,0,0,.1);
        width: 100%;
        // padding: 10px;
        margin-bottom: 10px;
        border-top-left-radius: 30px;
        // border-bottom-right-radius: 30px;

        // box-shadow: 0 15px 30px 1px grey;
        background: rgba(255, 255, 255, 0.90);
        background-color: #fff;
        text-align: center;
        border-radius: 5px;
        overflow: hidden;
        // margin: 5em auto;
        min-height: 350px;
        width: 100%;
        
        @media screen and (max-width:1150px) {
            display: flex;
            flex-direction: column;
            
        }

        .app__data-details{
            position: relative;
            text-align: left;
            overflow: hidden;
            padding: 30px;
            height: 100%;
            float: left;
            width: 40%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @media screen and (max-width:1150px) {
                width: 100%;
                height: 80%;
                padding-bottom: 3rem;
                
            }

            h1{
                // font-family: 'Bebas Neue', cursive;
                font-family: var(--font-alt);
                display: inline-block;
                position: relative;
                font-size: 25px;
                color: #344055;
                margin:1rem  0;


                &:before{
                    position: absolute;
                    content: '';
                    right: 0%; 
                    top: 0%;
                    transform: translate(25px, -15px);
                    // font-family: 'Bree Serif', serif;
                    font-family: var(--font-best);
                    display: inline-block;
                    background: #ffe6e6;
                    border-radius: 5px;
                    font-size: 14px;
                    padding: 5px;
                    color: white;
                    margin: 0;
                    animation: chan-sh 6s ease infinite;
                
                }  
                
            }
            p {
                font-family: 'EB Garamond', serif;
                font-family: var(--font-best);
                text-align: center;
                font-size: 18px;
                color: #7d7d7d;
                width: 100%;
                margin:1rem  0;

                    
            }

        }
        .app__img-treesinfo{
            transition: all 0.3s ease-out;
            display: inline-block;
            position: relative;
            overflow: hidden;
            height: 100%;
            float: right;
            width: 45%;
            width: 60%;
            display: inline-block;
            @media screen and (max-width:1150px) {
                width: 100%;
                padding-bottom: 3rem;
                
            }


            &:hover{
                .app__info-trees{
                    transform: translateX(0);
                }
                img {
                    transition: all 0.3s ease-out;
                    transform: scale(1.2, 1.2)
                }
            }
            img {
                width: 100%;
                height: 420px;

                object-fit: cover;
            }

            .app__info-trees{
                background: rgba(27, 26, 26, 0.9);
                // font-family: 'Bree Serif', serif;
                transition: all 0.3s ease-out;
                transform: translateX(-100%);
                position: absolute;
                line-height: 1.8;
                text-align: left;
                // font-size: 105%;
                cursor: no-drop;
                color: #FFF;
                min-height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                // display: flex;
                // flex-direction: column;
                // align-items: center;
                // justify-content: center;

                h1 {
                    text-align: center;
                    font-family: 'Bebas Neue', cursive;
                    font-family: var(--font-alt);
                    // display: inline-block;
                    // position: relative;
                    font-size: 23px;
                    color: #344055;
                    color: #fff;
                    // margin:1rem  0;
                }
                p{
                    font-size: 15px;
                    font-family: var(--font-best);
                    transition: 0.3s ease;
                    list-style: decimal;
                    font-size: 14px;
                    color: #fff;
                    padding: 0 10px;
                    text-align: center;
                    // font-family: 'Bebas Neue', cursive;
                    &:hover{
                        transform: translateX(10px) scale(1.02);
                    }

                }

                // ul{ 
                //     padding-left: 1rem;
                //     display: flex;
                //     flex-direction: column;
                //     li{
                //         transition: 0.3s ease;
                //         list-style: decimal;
                //         font-size: 14px;
                //         color: #fff;
                //         // padding: 10px;
                //         &:hover{
                //             transform: translateX(50px) scale(1.1);
                //         }
                //     }
                // }
            }
        }

    }
}

// img{
//     width: 100%;
//     height: 240px;
//     object-fit: cover;
//     border-top-left-radius: 30px;
//     // border-bottom-right-radius: 5px;
//     &:hover{
//         // box-shadow: 0px 0px 30px rgba(0,0,0,.1);
//         padding: 4px;
//         transition: .5s ease-in-out;
//     }
// }
// .data{
//     padding: 10px;

//     h1{
//         font-size: 16px;
//         text-transform: uppercase;
//         letter-spacing: 1px;
//         font-family: var(--font-alt);
//         text-align: center;
//     }
//     p{
//         font-size: 18px;
//         // text-transform: uppercase;
//         font-family: var(--font-alt);
//         font-weight: 500;
//         padding: 2px;
//         text-align: center;
//     }
// }