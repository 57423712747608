
.app__gallery-heading-one{
    margin-bottom: 5rem;
}
.app__gallery-heading-one div{
    width: 150px;
    margin: 0 auto;
    margin-top: 2rem;
    height: 1px;
    border-bottom:1px solid var(--color-blue);
}
.app__gallery-heading-one h1{
    font-family: var(--font-best);
    font-display: 34px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0;
}
.app__gallery {
    flex-direction: row;

    /* background: var(--color-secondary); */
    padding: 4rem 0 4rem 6rem;
}


.app__gallery-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    min-width: 500px;
    padding-right: 2rem;
}
.app__gallery-content h1{
    font-family: var(--font-best);
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 5rem;
    color: var(--color-darkblue);
}
.app__gallery-content p{
    font-family: var(--font-best);
    font-size: 18px;
    margin-right: 10rem;
    font-weight: 400;
    margin-bottom: 5rem;
    color: var(--color-darkblue);
}


.app__gallery-content button {
    font-family: var(--font-best);
    padding: .56em 2em;
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    color: #fff;
    background-color: var(--color-darkgreen);
}
.app__gallery-content button:hover{
    color: var(--color-darkgreen);
    text-decoration: underline;
    background-color: var(--white);
    border: 1px solid var(--color-darkgreen);
    transition: 0.4s ease-in;    
}


.app__gallery-images {
    flex: 1;
    display: flex;
    flex-direction: row;
    max-width: 50%;
    position: relative;
}


.app__gallery-images_container {
    display: flex;
    flex-direction: row;
    width: max-content;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.app__gallery-images_container::-webkit-scrollbar{
    display: none;
}

.app__gallery-images_card{
    position: relative;
    min-width: 400px;
    height: 380px;
    margin-right: 1rem;
    background-color: rgba(0, 0, 0, 0.86);
}
.gallery__image-icon{
    position: absolute;
    color: #fff;
    transition: 0.5s ease;
    font-size: 2rem;
    opacity: 0.5;
    cursor:pointer;
}
.app__gallery-images_card img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity:1;
    transition: 0.5s ease;
}
.app__gallery-images_card:hover img{
    opacity: 0.35;
}
.app__gallery-images_card:hover .gallery__image-icon{
    opacity: 1;
}

.app__gallery-images_arrows{
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    position: absolute;
    bottom: 50%;
}
.gallery__arrow-icon{
    color: var(--color-golden);
    font-size: 2rem;
    cursor: pointer;
    background: var(--color-black);
    border-radius: 15px;
}
.gallery__arrow-icon:hover{
    color: #fff;
}

@media screen and (min-width: 2000px) {
    .app__gallery-content{
        min-width: 1000px;
        padding-right: 4rem;
    }
    .app__gallery-images_card{
        min-width: 400px;
        height: 550px;
    }
    
}
@media screen and (max-width: 1150px) {
    .app__gallery{
        flex-direction: column;
    }    
    .app__gallery-images{
        min-width: 100%;
        margin: 5rem 0;
    }
}
@media screen and (max-width: 850px) {
    .app__gallery{
        padding: 4rem 0 4rem 4rem;
    }
}
@media screen and (max-width: 650px) {
    .app__gallery{
        padding: 4rem 0 4rem 1rem;
    }   
    .app__gallery-images_card{
        min-width: 240px;
        height: 320px;
    }
    .app__gallery-content{
        min-width: 100%;
    }
}
@media screen and (max-width: 450px) {
    .app__gallery-images_card{
        min-width: 200px;
        height: 320px;
    }
}
