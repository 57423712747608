.app__letter-container{
    width: 100%;
    height: 100vh;
    background-image: url('../../../assets/use//sunset.jpg');
    background-position: center;
    background-size: cover;
    

    .app__letter-camp{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1{
            font-family: var(--font-reg);
            color: #fff;
            font-size: 32px;
            letter-spacing: 2px;
            padding-top: 3rem;
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 45px;
            text-align: center;
        }
        p.p-one{
            font-size: 16px;
            letter-spacing: 1.4px;
            color: #fff;
            font-family: var(--font-reg);
            width: 80%;
            text-align: center;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        p.p-two{
            font-size: 16px;
            letter-spacing: 1.4px;
            color: #fff;
            font-family: var(--font-reg);
            width: 80%;
            text-align: center;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 3rem;
        }
    }
    .app__letter-form{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        input{
            padding: 1.2rem 7rem;
            font-family: var(--font-reg);
            border: none;
            outline: none;
            letter-spacing: .1rem;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            @media screen and (max-width: 650px) {
                padding: 1.2rem 5rem;
            }
        }
        div.bg{
            width: 3.5rem;
            height: 56.8px;
            background-color: #ffcc00;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            border: none;
            outline: none;
            cursor: pointer;
            &:hover{
                background-color: #000;
                svg{
                    color: #fff;
                }
            }
            svg{
                width: 1.6rem;
                height: 1.6rem;   
            }
        }
    }
    div.condition{
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        p{
            font-size: 16px;
            width: 40%;
            text-align: center;
            font-family: var(--font-reg);
            letter-spacing: 1px;
            margin-top: 2rem;
            @media screen and (max-width: 1200px) {
                width: 50%;
                // margin-top: 5rem;
            }
            @media screen and (max-width: 750px) {
                width: 80%;
                margin-top: 7rem;
            }
        }
    }
    .check-box{
        // color: #ffcc00;
        display: flex;
        justify-content: center;
        align-items: center;
        input{
            width: 20px;
            height: 20px;
        }
        p{
            color: #fff;
            padding-left: 1rem;
            font-size: 16px;
            font-family: var(--font-reg);
            letter-spacing: 1px;
        }
    }

}