.app__aboutcamp-head{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem 0;

    h1{
        font-size: 33px;
        font-weight: 900;
        font-size: 30px;
        font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
        &::after{
            border-bottom: 4px solid #fc0;
            bottom: -18px;
            content: '';
            left: 50%;
            position: absolute;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            width: 11%; 
        }  
    }
    
}

.app__aboutcamp-wrapper{
    width: 100%;

    div.app__aboutcontent{
        width: 100%;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        @media screen and (max-width: 650px) {
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }

        h1{
            font-size: 30px;
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 45px;
            letter-spacing: 1px;
            font-family: var(--font-alt);
            color: var(--color-darkblue);
            &:hover{
                // color: #fc0;
                transition: .3s ease-in-out;
            }
            @media screen and (max-width: 650px) {
                padding-left: 1rem;
                width: 100%;

            }
        }
        p{
            font-size: 17px;
            letter-spacing: 1px;
            font-family: var(--font-alt);
            width: 60%;
            color: var(--color-darkblue);
            @media screen and (max-width: 650px) {
                padding-left: 1rem;
                width: 100%;
            }
        }
    }


    div.app__aboutcamp-cards{
        width: 100%;
        display: grid;
        padding: 2rem 1rem 2rem;
        cursor: pointer;
        grid-template-columns: repeat(3, 1fr);
        @media screen and (max-width: 950px) {
            grid-template-columns: repeat(2, 1fr);      
        }
        @media screen and (max-width: 650px) {
            grid-template-columns: repeat(1, 1fr);      
        }
        gap: .5rem;

        .content{
            .contain{
                width: 100%;
                height: 50vh;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            img{
                width: 100%;
                height: 300px;
                object-fit: cover;
                position: relative;
                box-shadow: 0px 0px 0px auto rgba(0,0,0,0.1);
                // display: flex;
            }
            h1{
                width: 90%;
                text-transform: capitalize;
                font-family: var(--font-alt);
                display: flex;
                justify-content: center;
                color: #fff;
                position: absolute;
                align-items: center;
                background-color: teal;
                transform: .6s;
                opacity: 1;
                transform: scale(1);
                &:hover{
                    transition: .5s;
                    color: var(--color-darkblue);
                    background-color: beige;
                }
            }

            p{
                font-weight: 500;
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 1rem;
                width: 90%
            }
        }
    }
}