.app__bg-back{
    width: 100%;
    height: 90vh;
    position: relative;
    background-image: url('../../../assets/nature3.jpg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    position: relative; 
    -webkit-box-shadow: inset 0 0 100px 10px #000;    
    -moz-box-shadow: inset 0 0 10px 10px #000;
    box-shadow: inset 0 0 400px 100px rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;

    .content-now{
        width: 100%;
        position: absolute;
        top: 10%;
        padding-left: 2rem;
        @media screen and (max-width: 1150px) {
            top: 20%;
        }
        h1{
            font-family: var(--font-alt);
            font-size: 100px;
            line-height: 120px;
            color: #fff;
            letter-spacing: 3px;
            font-weight: 900;
            padding-bottom: 0;
            margin-bottom: 10px;
            span{
                color: #fc3;
            }
            @media screen and (min-width: 1550px) {
                font-size: 130px;
                line-height: 90px;
            }
            @media screen and (max-width: 850px) {
                font-size: 100px;
                letter-spacing: 3px;
                line-height: 120px;
            }
            @media screen and (max-width: 650px) {
                font-size: 90px;
                letter-spacing: 3px;
                line-height: 100px;
            }
        }
        p{
            font-family: var(--font-alt);
            color: #fff;
            max-width: 560px;
            font-size: 21px;
            padding-right: 10px;

            @media screen and (max-width: 650px) {
                font-size: 19px;
            }
        }

    }
}
.main-content{
    width: 100%;
    margin: auto;
    padding-top: 2rem;
    p{
        font-size: 18px;
        font-family: var(--font-best);
        line-height: 30px;
        width: 90%;
        margin: auto;
        padding: 3px 0;
    }
    h3{
        font-size: 21px;
        color: var(--color-darkblue);
        font-family: var(--font-best);
        width: 90%;
        // font-weight: 100!important;
        margin: auto;
        font-weight: 500;
    }
    .diff{
        font-family: var(--font-alt);
        display: flex;
        width: 90%;
        padding: 3px 0;
        align-items: center;
        // padding-left: 2rem;
        margin: auto;
        svg{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 40px;
            margin-right: 1rem;
        }
        li{
            list-style: decimal;
            display: flex;
            color: var(--color-darkblue);
        }

    }
}
