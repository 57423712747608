.app__achieve-head{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 3rem 0 1rem 0;
    h1{
        font-size: 30px;
        font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
        &::after{
            border-bottom: 4px solid #fc0;
            bottom: -8px;
            content: '';
            left: 50%;
            position: absolute;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            width: 8%;
        }
    }
}

.app__achieve-wrapper{
    width: 100%;
    margin: 3rem 0rem;
    // box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
    display: flex;
    @media screen and (max-width: 650px) {
        flex-direction: column;
        
    }

    .app__achieve-content{
        width: 50%;
        margin-left: 1rem;
        margin-right: 1rem;
        @media screen and (max-width: 650px) {
            width: 100%;
            margin: auto;
            
        }
        // box-shadow: 0px 0px 30px rgba(0,0,0,0.1);

        h1{
            font-family: var(--font-alt);
            color: var(--color-darkblue);
            font-size: 23px;
            font-weight: 600;
            letter-spacing: 1px;
            padding: 1rem;
            width: 75%;
        }
        p{
            font-family: var(--font-alt);
            font-size: 17px;
            padding:0 1rem;
            color: var(--color-darkblue);
            letter-spacing: 1px;
            width: 80%;
        }
        .achieve-btn{
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-top: 1rem;
            margin-bottom: 2rem;
            padding-left: 1rem;
            h2{
                font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
                text-transform: uppercase;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                font-weight: 600;
                font-size: 12px;
                letter-spacing: 3px;
                line-height: 1.5;
                background: #fc0;
                color: #000;
                left: -1px;
                cursor: pointer;
                // max-width: 75%;
                
                padding: .8rem 1.5rem;
                &:hover{
                    background-color: transparent;
                    border: 1px solid var(--color-darkblue);
                    transition: .5s ease-in-out;
                }
            }
        }

    }
    @keyframes zoominoutsinglefeatured {
        0% {
            transform: scale(1,1);
        }
        50% {
            transform: scale(.9,.9);
        }
        100% {
            transform: scale(1,1);
        }
    }
    .app__achieve-slider{
        position: relative;
        width: 50%;
        margin-left: 1rem;
        // box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
        margin-right: 1rem;
        animation: zoominoutsinglefeatured 9s infinite;
        @media screen and (max-width: 650px) {
            width: 100%;
            margin: auto;
            
        }



        img{
            position: inherit;
            width: 100%;
            height: 400px;
            object-fit: cover;
            transition: all .5s ease-in-out;


        }


    }

}