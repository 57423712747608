
.app__regen-bd{
    width: 100%;
    padding-top: 5.7rem;

    @media screen and (max-width: 650px) {
        padding-top: 4.7rem;
    }

}
.app__regenerative-bg{
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and(max-width:950px) {
        height: 60vh;   
    }
    @media screen and(max-width:650px) {
        height: 50vh;   
    }

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .app__content-regen-bf{
        position: absolute;
        bottom: 0;
        // background-color: rgb(43, 221, 221);
        background-color: rgb(146, 121, 89);
        color: aliceblue;
        padding: 5px 1rem;
        @media screen and (max-width:650px) {
            width: 90%;
        }
        
        h1{
            font-family: var(--font-alt);
            font-size: 44px;
            line-height: 60px;
        }

    }
}

.app__regenerative-container{
    width: 100%;
    margin-top: 3rem;

    .regenerative-heading{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 2rem;
        h1{
            font-size: 48px;
            font-family: var(--font-alt);
            line-height: 55px;
          
            font-weight: normal;
            &::after{
                border-bottom: 4px solid #000;
                bottom: -8px;
                content: '';
                left: 50%;
                position: absolute;
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translateX(-50%);
                width: 7%;
            }
            @media screen and (max-width: 550px) {
                font-size: 30px;
                line-height: 40px;
                text-align: center;
            }
        }

    }
    .regenerative-p{
        margin-top: 2rem;
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 2rem;
    
        p{
            // color: var(--color-darkblue);
            font-size: 18px;
            line-height: 36px;
            font-family: var(--font-best);
            width: 90%;
            margin:1rem 0;
            @media screen and (max-width: 550px) {
                font-size: 16px;
                line-height: 30px;
            }
        }
    
    }
    .regenerative-img{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;

        img{
            width: 50%;
            height: 50%;
            object-fit: cover;
        }
    }
    .regenrative-exp{
        width: 100%;
        margin: 3rem auto;
        
        .head{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 2rem;
            h1{
                font-size: 48px;
                line-height: 55px;
                font-family: var(--font-alt);
                font-weight: normal;
                letter-spacing: 1px;
                @media screen and (max-width: 550px) {
                    font-size: 33px;
                    line-height: 40px;
                }
            }
            .app__linre{
                width: 60px;
                height: 10px;
                border-bottom: 3px solid var(--color-black);
                margin-top: 1,4rem;
                margin-bottom: 2rem;
            }
        }
        .regenerative-loop{
            width: 90%;
            display: grid;
            margin: auto;
            // padding: 1rem 1rem;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
            @media screen and (max-width: 850px) {
                grid-template-columns: repeat(1, 1fr);
                
            }


            .loop{
                width: 100%;
                background: #FFFFFF;
                border: 1px solid #EBEFFA;
                box-sizing: border-box;

                &:hover{
                box-shadow: 0 0 30px rgba(0,0,0,.1);   
                }
                img{
                    width: 100%;
                    height: 340px;
                    object-fit: cover;
                }
                .loop-content{
                    width: 100%;
                    padding: 10px;
                    h1{
                        font-size: 20px;
                        font-family: var(--font-alt);
                        font-weight: normal;
                        text-align: center;
                        text-transform: capitalize;
                    }
                    p{
                        font-size: 16px;
                        // letter-spacing: 1px;
                        // padding-left: .8rem;
                        font-family: var(--font-best);
                        font-weight: 500;
                        text-align: center;
                    }
                }
            }

        }
    }

    .app__regen-porj-con{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: rem auto 1rem;

        h1{
            font-size: 45px;
            line-height: 55px;
            font-family: var(--font-alt);
            font-weight: normal;
            @media screen and (max-width: 550px) {
                font-size: 33px;
                line-height: 40px;
                text-align: center;
            }
        }
        .app__liner-proj{
            width: 80px;
            height: 10px;
            border-bottom: 3px solid #000;
            margin-bottom: 3rem;
        }
    }

    .regenerative-pro{
        width: 80%;
        display: flex;
        // align-items: center;
        // justify-content: center;
        margin: auto;

        @media screen and (max-width: 850px) {
            flex-direction: column-reverse;
        }

        .content{
            width: 50%;
            // box-shadow: 0px 0px 30px rgba(0,0,0,.1);
            padding: 1rem;
            margin: 1rem;
                
            @media screen and (max-width: 850px) {
                width: 100%;
            }
            h1{
                font-size: 20px;
                width: 100%;
                font-family: var(--font-alt);
                line-height: 25px;
                letter-spacing: 1px;
                font-weight: 900;
                // margin: auto;
            }
            p{
                font-size: 17px;
                margin: auto;
                width: 100%;
                font-weight: 500;
                font-family: var(--font-alt);
                line-height: 25px;
                letter-spacing: 1px;
                color: var(--color-darkblue);

            }
        }
        .pro-img{
            width: 50%;
            // box-shadow: 0px 0px 30px rgba(0,0,0,.1);
            margin: 1rem;
            @media screen and (max-width: 1150px) {
                height: 350px;
                margin-top: 3rem;
            }
            @media screen and (max-width: 850px) {
                width: 100%;
                margin-top: 1rem;
            }
            img{
                width: 100%;
                height: 300px;
                object-fit: cover;
                @media screen and (max-width: 1150px) {
                    height: 350px;
                }
            }

        }
    }
 
}

.app__renere-headme{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
