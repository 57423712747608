.forestry-gallery{
    width: 100%;

    .app__forestrygallery-head{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2rem 0;
        position: relative;
        h2{
            font-weight: 900;
            font-size: 39px;
            font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
            &::after{
                border-bottom: 4px solid var(--color-black);
                bottom: -18px;
                content: '';
                left: 50%;
                position: absolute;
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translateX(-50%);
                width: 8%; 
            }  
        }
    }
    .rights{
        display: flex;
        align-items: center;
        justify-content: center;

        h1{
            font-size: 18px;
            font-weight: 500;
            font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;

        }
    }
}