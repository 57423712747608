.dont-bb-now{
  width: 100%;
  padding-top: 5.7rem;

  @media screen and (max-width: 650px) {
    padding-top: 4.7rem;
  }
}

.app__content-head-donate{
  width: 90%;
  margin: auto;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;


  
  @media screen and (max-width: 950px) {
    flex-direction: column;
    width: 100%;
  }

  .done-content{
    width: 50%;
    // background-color: darkblue;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    min-height: 530px;
    margin: 0 1rem;
    
    @media screen and (max-width: 950px) {
      width: 100%;
      padding: 2rem;
    }
    .condon{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      // margin: 2rem 0;

      h1{
        font-size: 65px;
        // max-width: 350px;
        line-height: 75px;
        // letter-spacing: 3px;
        // font-weight: normal;
        font-family: var(--font-alt);
        font-family: 'Segoe UI', Tahoma, sans-serif;

        @media screen and (max-width: 650px) {
          font-size: 50px;
          line-height: 60px;
        }
        @media screen and (max-width: 500px) {
          font-size: 38px;
          line-height: 46px;
        }
      }
      h2{

        font-size: 18px;
        max-width: 350px;
        line-height: 65px;
        font-weight: normal;
        font-family: var(--font-best);
        font-family: 'Segoe UI', Tahoma, sans-serif;
        @media screen and (max-width: 500px) {
          font-size: 16px;
          line-height: 25px;
        }
      }

      p{
        font-size: 19px;
        font-weight: normal;
        font-family: var(--font-best);
        margin-bottom: 1rem;
        max-width: 470px;
        font-family: 'Segoe UI', Tahoma, sans-serif;
        @media screen and (max-width: 500px) {
          font-size: 16px;
          line-height: 29px;
        }
      }

      .done-btn{
        display: flex;
        width: 100%;
        margin: 1rem 0;
        @media screen and (max-width: 950px) {
          flex-direction: column;
        }
        a{
          width: 80%;
          // margin: 0 10px;
          @media screen and (max-width: 950px) {
            margin: 10px auto;
            width: 100%;
          }
          @media screen and (max-width: 550px) {
            margin: 10px auto;
            width: 100%;
          }
          button{
            width: 80%;
            min-height: 70px;
            border-radius: 5px;
            padding: 3rem auto;
            text-transform: capitalize;
            font-family: var(--font-best);
            font-size: 19px;
            line-height: 24px;
            font-family: 'Segoe UI', Tahoma, sans-serif;
            font-weight: normal;
            box-shadow: 0 0 30px rgba(0,0,0,.2);
            background-color: #c4d82e;
            text-align: center;
            // margin: 0 10px;

            @media screen and (max-width: 950px) {
              width: 100%!important;
              margin: 0;
            }
            @media screen and (max-width: 550px) {
              width: 100%!important;
              margin: 0;
              font-size: 15px;
              min-height: 46px;
              letter-spacing: 0;
              padding: 8px 0;
              border-radius: 30px;
            }
            &:hover{
              color: black;
            }
          }
          .one{
            &:hover{
              background-color: #000000;
              color: #fff;
            }
          }
          .two{
            background-color: #135cb0;
            color: #fff;
            &:hover{
              background-color: #000000;
              color: #fff;
            }
          }
        
        }
      }
    }

    .conner-cocilas{
      display: flex;
      margin-top: 1rem;
      img{
        width: 50px;
        height: 50px;
        padding: 5px;
        border-radius: 100px;
        @media screen and (max-width: 500px) {
          height: 40px;
          width: 40px;
        }
      }
    }

  }

  .done-img{
    width: 50%;
    // background-color: aquamarine;
    height: 500px;
    margin: 0 1rem;
    padding: 1rem;

    @media screen and (max-width: 950px) {
      width: 90%;
    }
    @media screen and (max-width: 550px) {
      width: 100%;
      height: 400px;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1px;
      @media screen and (max-width: 550px) {
        border-radius: 1px;

      }
    }

    


  }
}




.app__dondner-cont-now{
  width: 100%;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;

  h1{
    font-size: 38px;
    line-height: 45px;
    font-family: var(--font-alt);
    // font-weight: normal;
    margin: 3rem auto;
    font-weight: 600;
    font-family: 'Segoe UI', Tahoma, sans-serif;
    @media screen and (max-width: 550px) {
      font-size: 30px;
      padding: 0 2rem;
      text-align: center;
      line-height: 38px;


    }
  }
  p{
    font-size: 19px;
    max-width: 750px;
    margin: auto;
    text-align: center;
    font-family: var(--font-best);
    font-size: 19px;
    padding: 0 10px;
    font-weight: 400;
    font-family: 'Segoe UI', Tahoma, sans-serif;

  }
}

.app__content-donationn-container{
  width: 70%;
  min-height: 100vh;
  margin: 2rem auto;
  display: grid;
  background-color: #fff;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media screen and (max-width: 950px) {
    width: 90%;
    grid-template-columns: repeat(2, 1fr);
  }
  

  @media screen and (max-width: 650px) {
    width: 90%;
    grid-template-columns: repeat(1, 1fr);
  }
  
  .app__doner-conetnt-wrap{
    width: 100%;
    margin: 20px auto;
    // background-color: #fff;
    // border-right-color: black;
    // border-right: 1px solid black;
    // padding-left: 10px;
    padding: 1rem;
    // border-right-width: 1px ;
    cursor: pointer;

    &:hover{
      box-shadow:0 0 30px rgba(0,0,0,.1);
    }
    @media screen and (max-width: 650px) {
      border-right: none;
    }
    // margin: 10px;
    img{
      width: 100%;
      height: 300px;
      object-fit: cover;
      // border-radius: 5px;
      // padding: 10px;
      box-shadow: 0 0 30px rgba(0,0,0,.1);
      background-color:rgb(240, 248, 255,.4);
      
    }
    .cntont{
      h1{
        font-size: 26px;
        font-family: var(--font-alt);
        // font-weight: normal;
        line-height: 37px;
        font-family: 'Segoe UI', Tahoma, sans-serif;
        @media screen and (max-width: 550px) {
          font-size: 22px;
          line-height: 30px;
        }
      }
      p{
        font-family: var(--font-best);
        font-size: 19px;
        // max-width: 240px;
        line-height: 30px;
        font-family: 'Segoe UI', Tahoma, sans-serif;
        @media screen and (max-width:550px) {
          width: 100%;
          font-size: 16px;
          line-height: 28px;
          // min-width: 500px;

        }

      }
      .conco-btn{
        margin-top: 2rem;
        button{
          border-radius: 3px;
          font-size: 16px;
          text-transform: capitalize;
          font-weight: normal;
          font-family: var(--font-best);
          font-size: 18px;
          box-shadow: 0 0 30px rgba(0,0,0,.3);
          background-color: #c4d82e;
          &:hover{
            color: #fff;
            background-color: #000000;
          }
          @media screen and (max-width:550px) {
            width: 90%;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            border-radius: 30px;
            // line-height: 28px;
            // min-width: 500px;
  
          }
        }
      }


    }
  }
  
}


.app__donate-questions{
    width: 100%;
    min-height: 70vh;
    background-color: rgba(218, 213, 213, 0.753);
    display: flex;
    flex-direction: column;
    padding: 2rem;
    // align-items: center;
    // justify-content: center;
    // padding-top: 5rem;


    h1{
      width: 100%;
      display: flex;
      // align-items: center;
      // justify-content: center;
      max-width: 600px;
      line-height: 56px;
      margin: 3rem auto 0;
        font-size: 40px;
        font-family: 'Segoe UI', Tahoma, sans-serif;
        font-weight: 400;

      @media screen and (max-width: 550px) {
        font-size: 30px;
        line-height: 38px;
      }
    }

    p{
      max-width: 600px;
      // line-height: 50px;
      margin: 1rem auto ;
        font-size: 18px;
        font-family: 'Segoe UI', Tahoma, sans-serif;
        font-weight: 400;

        @media screen and (max-width: 550px) {
          font-size: 16px;
        }
    }
    ul{
      display: flex;
      flex-direction: column;
      font-weight: 400;
      margin: 1rem auto ;
      font-size: 18px;
      font-family: 'Segoe UI', Tahoma, sans-serif;
      font-weight: 400;
      max-width: 600px;
      padding-left: 1rem;
      @media screen and (max-width: 550px) {
        font-size: 16px;
      }
      li{
        color: #000000;
        list-style: decimal;
      }

    }


}