
.app__get-here{
    width: 100%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1{
        font-family: var(--font-alt);
        font-size: 40px;
        // text-transform: uppercase;
        color: var(--color-darkblue);
        font-size: 56px;
        font-weight: normal;
        font-family: var(--font-best);
        color: var(--color-darkblue);
        text-transform: capitalize;
        @media screen and (max-width: 650px) {
            font-size: 30px;
            
        }
        // font-weight: normal;
    }
    .app__howed{
        width: 100px;
        height: 10px;
        border-bottom: 3px solid var(--color-black);
        margin-bottom: 2rem;
        @media screen and (max-width: 650px) {
            width: 60px;
            
        }
    }
}

.map-wrap{
    width: 100%;
    // background-color: black;
    height: 70vh;
    margin: 1rem auto 0rem;

    @media screen and (min-width: 850px) {
        width: 100%;
        height: 85vh;
    }

}