.app__endtrees-head{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0; 
    h1{
        width: 100%;
        font-size: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: normal;
        
        font-family: var(--font-alt);
        @media screen and (max-width: 540px) {
            font-size: 28px;
            text-align: center;
        }
        &::after{
            border-bottom: 4px solid var(--color-black);
            bottom: -11px;
            content: '';
            left: 50%;
            position: absolute;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            width: 7%;
        }
    }
}

.app__endtrees{
    position: relative;
    width: 100%;
    margin: 3rem auto 5rem;

    img{
        width: 100%;
        padding: 0 1px;
        height: 450px;
        object-fit: cover;
        cursor: pointer;
        @media screen and (max-width: 650px) {
            height: 300px;
        }
        @media screen and (max-width: 450px) {
            height: 260px;
        }
    }
    .app__nametop{
        position: relative;
        width: 100%;
        span{
            background: #0f3460;
            background: red;
            color: #fff;
            font-size: 18px;
            padding: 5px 2.4rem;
            border-radius: 50px;
            text-transform: capitalize;
            margin: 5px;
            cursor: pointer;
            position: absolute;
            font-family: var(--font-best);
            top: 10px;

            @media screen and (max-width: 650px) {
                padding: 3px 10px;
                font-size: 14px;
            }

            .tleft {
                left: 0;
            }
            .tright {
                background-color: aliceblue;
                color: black;
                position: absolute;
                right: 0;
            }
        }
      }
  
}


// @media screen and (max-width: 850px) {
    
//     .slick-slider{
//         .slides-to-show{
//         display: 2;
//         }
//     }
// }

