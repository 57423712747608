.app__form-dontae-now{
    width: 100%;
    // height: 200vh!important;
    // background-image: url('../../../assets/mango.jpg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    margin-bottom: 3rem;
    padding:2rem 10px;


    .app__main-pay-container{
        // background-color: blue;
        // background-color: rgb(8, 8, 80,.7);
        display: flex;
        flex-direction: column;
        max-width: 700px;
        align-items: center;
        justify-content: center;
    }
}