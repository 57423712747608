.app__ourcamp-inspiration{
    width: 100%;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    h1{
        font-size: 30px;
        font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;

        &::after{
            border-bottom: 4px solid #fc0;
            bottom: -18px;
            content: '';
            left: 50%;
            position: absolute;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            width: 7%;
        }
    }
}

.app__ourcamp-profiles{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 4rem;

    .app__ourcamp-profile_item{
        cursor: pointer;
        position: relative;
        width: 400px;
        height: 480px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin: 1rem;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

       .card-img{
        // position: absolute;
        // top: 0;
        // left: 0;
        height: 250px;
        // width: 450px;
        position: relative;
        left: -1px;
        top: -1px;
        width: calc( 100% + 2px);
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .tripcard-badge{
            font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
            text-transform: uppercase;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 3px;
            line-height: 1.5;
            background: #fc0;
            color: #000;
            left: -1px;
            max-width: 75%;
            padding: 3px 9px;
            position: absolute;
            top: 15px;
            z-index: 9;
        }
       }
       .cards-price{
        position: relative;
        display: flex;
        align-items: center;

        .line{
            position: absolute;
            width: 1px;
            height: 25px;
            border-left: 1px solid grey;
        }
        :nth-child(1){
            left: 17px;
        }
        :nth-child(2){
            right:15rem;
        }
        p{
            padding: 10px 20px 0px 25px;
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
            color: var(--color-darkblue);         
        }
       }
       .cards-content{
        padding: 10px 20px 20px 20px;
        h1{
            font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
            -webkit-font-smoothing: antialiased;
            font-weight: 400;
            width: 100%;
            letter-spacing: 0;
            padding-bottom: 10px;
            letter-spacing: .1px;
            font-size: 25px;
            line-height: 1.429;
        }
        p{
            font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
            -webkit-font-smoothing: antialiased;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 3px;
            line-height: 1.5;
            text-transform: none;
            
        }
       }
    }

}