.app__donation-bg{
    width: 100%;
    min-height: 100vh;
    background-image: url('../../../assets/nature3.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    -webkit-box-shadow: inset 0 0 100px 10px #000;    
    -moz-box-shadow: inset 0 0 10px 10px #000;
    box-shadow: inset 0 0 1000px 100px rgba(0, 0, 0, 0.75);
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 2rem;



    .donate-logo{
        display: flex;
        align-items: center;
        justify-content: center;
        // position: absolute;
        img{
            object-fit: cover;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
            border-radius: 100px;
            width: 80px;
            height: 80px;
        }
    }
}