.app__containeme-crops{
    width: 100%;
    padding-top: 5.7rem;

    @media screen and (max-width: 650px) {
        padding-top: 4.7rem;
    }
}

.app__container-crops-bg{
    width: 100%;
    height: 70vh;
    background-image: url('../../../../../../assets/team1.jpg');
    background-position: center;
    background-size: cover;
    -webkit-box-shadow: inset 0 0 100px 10px #000;    
    -moz-box-shadow: inset 0 0 10px 10px #000;
    box-shadow: inset 0 0 400px 100px rgba(0, 0, 0, 0.9);
    padding: 3rem;

    @media screen and(max-width: 850px) {
        height: 50vh;
    }


    .app__container-crops-bg-info{
        padding: 1rem;
        // margin-top: 2rem;

        h1{
            font-size: 66px;
            max-width: 950px;
            font-family: var(--font-alt);
            letter-spacing: 3px;
            line-height: 78px;
            text-transform: uppercase;
            color: #fff;
            span{
                color: darkorange;
            }
            @media screen and (min-width: 2000px) {
                font-size: 78px;
                text-transform: uppercase;
                line-height: 90px;
            }
            @media screen and (max-width: 850px) {
                font-size: 40px;
                text-transform: uppercase;
                line-height: 46px;
            }
            @media screen and (max-width: 650px) {
                font-size: 30px;
                letter-spacing: 1px;
                text-transform: uppercase;
                line-height: 46px;
            }
        }

        p{
            color: #fff;
            max-width: 950px;
            font-family: var(--font-alt);
            font-size: 18px;
            font-weight: 400;
            @media screen and (min-width: 2000px) {
                font-size: 21px;
               
            }
            @media screen and (max-width: 650px) {
                font-size: 16px;
               
            }
        }

    }

}


.app__content-cropes-header{
    width: 90%;
    margin: auto;
    padding-bottom: 2rem;

    h1{
        font-size: 33px;
        font-family: var(--font-alt);
        text-transform: capitalize;
        color: var(--color-darkblue);
    }

    p{
        font-size: 18px;
        font-family: var(--font-alt);
        max-width: 850px;

    }
}


.app__content-crops-wrapper{
    width: 90%;
    margin: 1rem auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    gap: 1rem;

    @media screen and (max-width: 850px) {
        grid-template-columns: repeat(1, 1fr);
    }

    .content-onecrop{
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
        width: 100%;
        // padding: 4px;
        border-top-left-radius: 30px;
        
        img{
            width: 100%;
            height: 60vh;
            object-fit: cover;
            border-top-left-radius: 30px;
            @media screen and (max-width: 850px) {
                height: 50vh;
            }
            @media screen and (max-width: 500px) {
                height: 45vh;
            }

            &:hover{
                padding: 8px;
                transition: .5s ease-in-out;
            
            }
        }
        .content-onmoi{
            padding: 1rem;
            width: 100%;

            h1{
                font-size: 20px;
                font-family: var(--font-alt);

                span{
                    font-size: 19px;
                    font-weight: 400;

                }
            }
        }

    }
}

.btn-goback-crops{
    width: 100%;
    margin: auto;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;

    button{

        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .8rem 1.8rem;
        letter-spacing: 3px;
        margin: 1rem;

        svg{
            font-size: 23px;
        }
        background-color: var(--color-black);
        color: #fff;

        &:hover{
            background-color: var(--color-algeagreen);
            color: #000;
        }
        @media screen and(max-width: 650px) {
            padding: .7rem 1.4rem;
            letter-spacing: 1px;
            margin: 7px;
        }
        @media screen and(max-width: 400px) {
            padding: .7rem 1.2rem;
            letter-spacing: 1px;
            // text-transform: capitalize;
            font-size: 11px;
            margin: 4px;
            svg{
                display: none;
            }
        }
    }
    button.crops-three{
        background-color: var(--color-algeagreen);
        color: #000;

        &:hover{
            background-color: transparent;
            border: 1px solid #000;
            transition: .4s ease-in-out;
        }
    }
}