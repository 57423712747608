.app___newabout-bg{
    width:100%;
    padding-top: 5.7rem;

    @media screen and (max-width: 650px) {
        padding-top: 4.7rem;
        
    }
}

.app__newabout-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .app__newer-head{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        h1{
            font-family: var(--font-alt);
            font-size: 45px;
            font-weight: normal;

        }
        .app__lenrern{
            width: 50px;
            height: 10px;
            border-bottom: 3px solid var(--color-black);
        }
    }
    h2{
        max-width: 1100px;
        text-align: center;
        margin-top: 1.5rem;
        // padding: 10px;
        // font-size: 34px;
        // font-weight: normal;
        // line-height: 40px;
        font-family: var(--font-best);
        font-size: 20px;
        padding-left: 1rem;
        font-weight: 500;
        line-height: 34px;
        // color: var(--color-darkblue);
        @media screen and (max-width:850px) {
            width: 100%;
            padding: 0 5px 0px 2rem;
            font-size: 19px;
            line-height: 35px;
            text-align: left;
            
        }

    }
    p{
        color: #243659;
        font-size: 22px;
        font-weight: 400;
        line-height: 39.6px;
        margin-top: 3rem;

        font-family: var(--font-best);
        max-width: 850px;
        padding: 10px;
        text-align: center;
        font-weight: normal;
        @media screen and (max-width: 650px) {
            // padding: 0 1rem;
        }    
    }


}

.app__newbout-headme{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem auto 3rem;

    h1{
        font-size: 40px;
        font-family: var(--font-alt);
        font-weight: normal;

    }
    .app__newavil{
        width: 50px;
        height: 10px;
        border-bottom: 3px solid var(--color-black);
    }
}

.app__aboutus-org{

    width: 90%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    margin: 1rem auto;

    @media screen and (max-width: 950px) {
    grid-template-columns: repeat(2, 1fr);
        
    }
    @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
        
    }

    .content{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 2rem;
        img{
            width: 150px;
            height: 150px;
            object-fit: cover;
            cursor: pointer;
        }
        h1{
            font-size: 19px;
            color: var(--color-darkblue);
            font-family: var(--font-best);
        }
        p{
            font-size: 18px;
            max-width: 260px;
            color: var(--color-darkblue);
            font-family: var(--font-best);
            text-align: center;

        }
    }
}

.app__newabout-img{
    width: 90%;
    display: flex;
    margin: 3rem auto;

    @media screen and (max-width: 850px) {
        width: 100%;
    }

    .thw-imgages{
        width: 90%;
        margin: auto;
        height: 50%;
        // height: 50vh;
        display: grid;
        // background-color: #243659;
        position: relative;
        gap: 2px;
        grid-template-columns: repeat(4, 1fr);
        @media screen and (min-width: 1150px) {
            // gap: 5px;
           width: 80%;
        }
        @media screen and (max-width: 850px) {
            // gap: 5px;
            grid-template-columns: repeat(2, 1fr);
            flex-direction: column;
        }
     
       .img{
            max-width: 100%;
            max-height: 250px;
            position: relative;

            @media screen and (max-width: 1150px) {
                max-height: 200px;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                // position: absolute;
            }
       }

       @media screen and (max-width: 850px) {
        width: 90%;
        margin: auto;
        padding-bottom: 1rem;
    }

    }
    // .content{
    //     width: 50%;
    //     padding-left: 2rem ;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     @media screen and (max-width: 850px) {
    //         width: 100%;
    //         margin: auto;
    //         padding-left: 2rem;
            
    //     }
        
    //     p{
    //         width: 100%;
    //         font-family: var(--font-best);
    //         margin: 0;
    //         padding: 0;
    //         font-size: 18px;
    //         padding-right: 10px;
    //         // line-height: 35px;

    //         @media screen and (max-width: 850px) {
    //             font-size: 16px;
    //             // line-height: 30px;
    //         }

        
    //     }
    //     // height: 100%;
    //     // display: flex;
    //     // align-items: center;
    //     // justify-content: center;
    //     // margin: auto;
    //     // padding-left: 1rem;
    //     // font-family: var(--font-best);
    //     // color: var(--color-darkblue);
    //     // line-height: 33px;
    //     // font-size: 19px;
    // }

}