.app__aboutnew-head{
    width: 100%;
    margin: 4rem auto 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1{
        font-size: 45px;
        font-weight: normal;
        font-family: var(--font-alt);
    }
    .app__aboutnew-liner{
        width: 70px;
        height: 10px;
        border-bottom: 3px solid var(--color-black);
        margin-bottom: 2rem;
    }
}