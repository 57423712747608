.photogallery-gallery{
    width: 95%;
    // margin: 5rem auto 2rem;

    // .app__photogallery-gallery-head{
    //     width: 100%;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: center;
    //     margin: 4rem auto 2rem;
    //     position: relative;
    //     h2{
    //         font-weight: 900;
    //         font-size: 45px;
    //         font-weight: normal;
    //         font-family: var(--font-alt);
    //         letter-spacing: 3px;
    //         line-height: 60px;
    //     }
    //     .app__photogallery-line{
    //         width: 200px;
    //         height: 10px;
    //         border-bottom: 3px solid var(--color-black);
    //         margin-bottom: 2rem;
    //     }
    // }

}