.htetrees-div{
    width: 100%;
    padding-top: 5.7rem;
    @media screen and (max-width: 650px) {
        padding-top: 4.7rem;
    }
}


.app__thetreesdetails-bg{
    width: 100%;
    height: 70vh;
    background-image: url('../../../../../../assets/team1.jpg');
    background-position: center;
    background-size: cover;
    -webkit-box-shadow: inset 0 0 100px 10px #000;    
    -moz-box-shadow: inset 0 0 10px 10px #000;
    box-shadow: inset 0 0 400px 100px rgba(0, 0, 0, 0.9);
    padding: 3rem;

    .app__thetreesdetails-bg-data{
        width: 100%;
        padding-top: 2rem;
    }

    h1{
        font-size: 60px;
        font-family: var(--font-alt);
        color: #fff;
        line-height: 70px;
        letter-spacing: 2px;
        span{
            color: darkorange;
        }

        @media screen and (min-width: 2000px) {
           font-size: 78px;
           line-height: 85px;
        }
        @media screen and (max-width: 850px) {
            font-size: 40px;
            letter-spacing: 1px;
            line-height: 50px;
        }
    }

    p{
        color: #fff;
        max-width: 950px;
        font-size: 18px;
        font-family: var(--font-alt);

        @media screen  and (min-width: 2000px){
            font-size: 20px;
        }
        @media screen  and (max-width: 650px){
            font-size: 16px;
        }
    }
}


.app__tree-details-infoon{
    width: 90%;
    // padding: 2rem;
    margin: auto;

    h1{
        font-size: 30px;
        font-family: var(--font-alt);
        color: var(--color-darkblue);
        text-transform: uppercase;
    }

    .trees-data-line{
        width: 150px;
        height: 10px;
        border-bottom: 3px solid var(--color-darkblue);
        margin-bottom: 1rem;
    }
    p{
        font-size: 18px;
        font-family: var(--font-alt);
    }
    h3{
        font-size: 16px;
        font-family: var(--font-alt);
        margin-top: 1rem;
        font-weight: 500;
    }
}


.app__thetrees-data-details{
    width: 90%;
    min-height: 100vh;
    margin: 2rem auto;
    // box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    
    @media screen and (max-width: 1050px) {
        grid-template-columns: repeat(1, 1fr);
    }

    .id-tree-one{
        // width: 50%;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;


        img{
            width: 100%;
            // height: 340px;
            height: 60vh;
            object-fit: cover;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;

            &:hover{
                padding: 1rem;
                transition: .5s ease-in-out;
            }

            @media screen and (max-width: 850px) {
                // height: 450px;
                height: 50vh;
            }
        }
        .containe-me-child-trees{
            padding: 1rem;
        }
        h1{
            font-family: var(--font-alt);
            font-size: 16px;
            strong{
                text-transform: capitalize;
                font-size: 20px;
                letter-spacing: 2px;
                padding-left: 10px;
                font-weight: 900;
            }
            span{
                font-size: 18px;
                font-weight: 500;
                font-family: var(--font-alt);
            }
        }

        ol{
            padding: 0 1rem;
            li{
                font-size: 18px;
                list-style-type: disc;
                font-family: var(--font-alt);
            }
        }

    }
}

.go-back-to-trees{
    width: 90%;
    margin: auto;

    button{
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
        color: #fff;
        letter-spacing: 3px;
        padding: .8rem 1.5rem;

        svg{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 25px;
        }

        &:hover{
            background-color: var(--color-algeagreen);
            color: #000;
        }
    }
}