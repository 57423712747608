.app__comm-d{
    width: 100%;
    padding-top: 5.7rem;
    @media screen and (max-width: 650px) {
        padding-top: 4.7rem;
    }
}

.app__comm-bg{
    width: 100%;
    height: 80vh;
    position: relative;

    // .app__comm-bg-cont{
    //     width: 100%;
    //     position: absolute;
    // }
    // .cont-me-comm{
    //     width: auto;
    //     position: absolute;
    //     bottom: 1rem;
    //     background-color: #ac804e;
    //     padding: 5px 10px;
    //     left: 10%;
    //     // right: 20%;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;

    //     h1{
    //         font-size: 30px;
    //         columns: #fff;
    //         // line-height: 79px;
    //         text-align: center;
    //         font-family: var(--font-best);
    //         text-transform: uppercase;
    //         color: #fff;
    //         @media screen and (max-width: 650px) {
    //             font-size: 23px;
    //         }
    //     }
    

    // }
}

.app__content-comm{
    width: 100%;
    margin: 6rem 0;
    display: flex;
    // flex-direction: column;

    h1{
        font-family: var(--font-best);
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 1050px;
        margin: auto;
        text-align: center;
        font-size: 34px;
        line-height: 43px;
        font-weight: normal;
        margin: 2rem auto 0rem;


        @media screen and (max-width: 650px) {
            font-size: 20px;
            line-height: 33px;
            padding: 10px;
            // max-width: 600px;
        }

        // @media screen and (max-width: 850px) {
        //     font-size: 28px;
        //     line-height: 38px;
        //     margin-bottom: 1rem;
        // }

    }

}

.app__comuunity-outreachprog{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    padding-bottom: 0;

    // h1{
    //     font-size: 30px;
    //     font-family: var(--font-best);
    //     font-weight: normal;

    // }
    // .outreach-liner{
    //     width: 100px;
    //     height: 10px;
    //     color: #000;
    //     border-bottom: 3px solid var(--color-black);
    //     margin-bottom: 1rem;

    // }

    .app__communit-programs{
        width: 100%;
        margin: auto;
        // padding: 2rem;
        display: flex;
        flex-direction: column;
        // margin: 1rem auto;
        @media screen and (max-width: 850px) {
            width: 100%;
            flex-direction: row;
        }

        .contain-me{
            display: flex;
            flex-direction: column;
            // margin-bottom: 1rem;

            @media screen and (max-width: 850px) {
                flex-direction: column;
                width: 100%;
            }

            img{
                width: 50%;
                height: 500px;
                object-fit: cover;
                background-color: transparent;
                @media screen and (max-width: 850px) {
                    width: 100%;
                    // height: 400px;
                    // height: 100%;
                    margin: auto;
                    // height: 100%;
                }
                @media screen and (max-width: 650px) {
                    width: 100%;
                    // height: 400px;
                    // height: 100%;
                    margin: auto;
                    // height: 100%;
                }
    
            }
            .app__conte-co{
                width: 50%;
                display: flex;
                flex-direction: column;
                // align-items: center;
                justify-content: center;
                
                padding-left: 5rem;

                @media screen and (max-width: 850px) {
                    width: 100%;
                    margin-bottom: 1rem;
                    padding-left: 2rem;
                    // flex-direction: row;
                }
                
                @media screen and (max-width: 550px) {
                    width: 100%;
                    margin-bottom: 0rem;
                    padding-left: 0rem;
                    // flex-direction: row;
                    .app__btbt{
                       padding-left: 0;
                    }
                }
                

                h1{
                    // background-color: darkcyan;
                    // padding: 1rem;
                    // color: #fff;
                    width: 100%;
                    font-size: 36px;
                    text-align: start;
                    line-height: 55px;
                    font-family: var(--font-alt);
                    color: #470a3c;
                    text-transform: uppercase;
                    @media screen and (max-width: 550px) {
                       font-size: 28px;
                       padding-left: 2rem;

                    }
                }
                p{
                    font-size: 18px;
                    font-family: var(--font-best);
                    line-height: 30px;
                    max-width: 600px;
                    // width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    margin: 0;
                    @media screen and (max-width: 550px) {
                        font-size: 16px;
                        padding-left: 2rem;
                        // width: 100%!important;
                     }
                }
                .app__btbt{
                    margin-top: 2rem;
                    // width: 100%;
                    border-radius: 0;
                    display: flex;
                    // align-items: flex-start;
                    // padding: 1rem 2rem;
                }
                button{
                    // padding: 1rem 2rem;
                    border-radius: 0;
                    background-color: #a9218f;
                    color: #ffffff;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    cursor: pointer;

                    @media screen and (max-width: 550px) {
                        width: 100%;
                     }


                }
            }


        }

        .app__coom-imgm{
            width: 100%;
            margin: auto;
            // padding: 2rem;
            display: flex;
            
            @media screen and (max-width: 850px) {
                flex-direction: column;
            }
           
        }
        .app__coom-img{
            width: 100%;
            margin: auto;
            // padding: 2rem;
            display: flex;
            flex-direction: row-reverse;
            padding-left: 0rem;

            @media screen and (max-width: 850px) {
                flex-direction: column;
            }
        }
    }

}

.ap__commm-links{
    width: 100%;
    background-color: #580649;
    // padding-top: 0!important;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-around;
    padding: 1rem;

    @media screen and (max-width: 850px) {
        // margin-bottom: 1rem;
        flex-direction: column;
    }

    .one{
        display: flex;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3rem ;
        h1{
            color: #ffffff;
            font-family: var(--font-best);
            text-transform: uppercase;
            letter-spacing: 3px;
            font-size: 17px;
            font-weight: normal;
            @media screen and (max-width: 550px) {
                font-size: 15px;
             }
        }
        h2{
            color: #ffffff;
            font-family: var(--font-alt);
            font-size: 27px;
            text-transform: uppercase;
            text-align: center;
            line-height: 37px;
            @media screen and (max-width: 850px) {
               text-align: center;
            }
            @media screen and (max-width: 550px) {
                font-size: 24px;
             }
            
        }
    }
    .two{
        display: flex;
        flex-direction: column;
        display: flex;
        // flex-direction: column;
        // padding: 0 4rem ;
        border-right: 1px solid var(--color-white);
        border-left: 1px solid var(--color-white);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3rem;
        @media screen and (max-width: 550px) {
            border-right: none;
            border-left: none;
         }


        h1{
            color: #ffffff;
            font-family: var(--font-best);
            margin-right:7rem;
            margin-left:7rem;
            font-size: 17px;
            letter-spacing: 2px;
            text-transform: uppercase;
            @media screen and (max-width: 550px) {
               margin-right: 0;
               margin-left: 0;
             }

            

        }
        h2{
            color: #ffffff;
            font-family: var(--font-alt);
            // letter-spacing: 3px;
            text-transform: uppercase;
            font-size: 28px;
            line-height: 37px;
        }

    }
    .three{
        display: flex;
        flex-direction: column;
        // padding: 2rem;
        align-items: center;
        justify-content: center;
        padding: 3rem;
        h1{
            color: #ffffff;
            font-family: var(--font-best);
            font-size: 18px;
            font-weight: normal;
            font-size: 17px;
            line-height: 27px;
            letter-spacing: 2px;
            text-transform: uppercase;
            text-align: center;
        }
        h2{
            color: #ffffff;
            font-family: var(--font-alt);
            font-size: 24px;
            text-transform: uppercase;
            font-size: 28px;
            line-height: 37px;
            text-align: center;
            line-height: 36px;
            width: 100%;
        }
    }
}

