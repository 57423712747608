.app__footer{
    width: 100%;
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 0;
    background: var(--color-darkblue);
    background-color: #080E3F;
    background-color: #000000;

    .app__footer-liner-in{
        margin: 3rem auto;
        width: 90%;
        height: 10px;
        border-color: #3A417CC2;
        border-bottom: 3px solid #3A417CC2;
    }



    // background-color: #000;
}

.app__footer-links{
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    // @media screen and (max-width: 1150px) {
    //     grid-template-columns: repeat(3, 1fr);
    // }

    @media screen and (max-width: 650px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 450px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.app__footer-links_contact,
.app__footer-links_logo,
.app__footer-links_work,
.app__footer-links_camp,
.app__footer-links_work{
    
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
    width: 100%!important;
    margin: 0!important;
    // margin: 1rem;
}


.app__footer-links_work{
    width: 100%;
    h1{
        // color: var(--color-algeagreen);
        color: #fff;
        font-family: var(--font-alt);
        font-size: 16px;
        font-weight: 900;
        text-transform: uppercase;

    }

    p:nth-child(2n+1){
        margin-bottom: 1rem;
        font-family: var(--font-alt);
        font-size: 14px;
        color: #fff;
    }
    p:nth-child(2n+2){
        color: #fff;
        font-size: 11px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: 900;
        // color: #000;
    }
}
.app__footer-links_logo,
.app__footer-links_work,
.app__footer-links_camp,
.app__footer-links_contact{
    width: 100%;
    .header-inf-line{
        width: 100%;
        
        .linerme{
            width: 30px;
            height: 1rem;
            border-bottom: 3px solid var(--color-blue);
            margin-bottom: 1rem;
        }
    }
    h1{
        width: 100%;
        // color: var(--color-algeagreen);
        color: #fff;
        font-family: var(--font-best);
        letter-spacing: 1px;
        font-size: 13px;
        font-weight: 900;
        text-transform: uppercase;

    }
    a{
        font-size: 14px;
        // letter-spacing: 1px;
        padding: 5px 0;
        color: #fff;
        font-family: var(--font-best);
        font-weight: 300;
       
        &:hover{
            transform: all .3s ease;
            color: aliceblue;
        }
    }
    @media screen and (max-width: 650px) {
        h1{
            font-size: 11px;
        }
    }

}

.app__footer-links_contact{
    a:nth-child(3){
        color: var(--color-blue);
        font-size: 17px;
        width: 100%;
        font-family: var(--font-best);
        font-weight: 400;
    }
}


.app__footer-links_icons svg{
    color: var(--color-white);
    margin: 0.5rem;
    font-size: 24px;
    /* background-color: var(--color-black); */
    cursor: pointer;
}


.app__footer-links_icons svg:hover{
    color: var(--color-golden); 
}

.app__liner{
    width: 90%;
    margin: 2rem auto;
    height: 1rem;
    border-color: #3A417CC2;
    border-bottom: 3px solid #3A417CC2;
    border-width: 0px 0px 3px 0px;
    border-radius: 0px 0px 0px 0px;
}

.footer__copyright{
    margin: 3rem 0 2rem;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    @media screen and (max-width:850px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width:650px) {
        grid-template-columns: repeat(1, 1fr);
        width: 90%;
    }
    p{
        width: 100%;
        font-size: 16px;
        color: #fff;
        font-family: var(--font-best);
        font-weight: 500;
        @media screen and (max-width: 550px) {
            font-size: 12px;
            line-height: 20px;
        }
        // letter-spacing: 1px;
    }
    img{
        width: 40px;
        height: 40px;
        margin-left: 5.5rem;
        object-fit: cover;
        @media screen and (max-width: 950px) {
            margin-left: 2.5rem;;
        }
        @media screen and (max-width: 550px) {
            width: 25px;
            height: 25px;
            border-radius: 100px;
            margin-left: 0;
        }
    }
    .socials{
        display: flex;
        width: 100%;
        align-items: center;
        // justify-content: space-between;
        svg{
            color: #fff;
            font-size: 23px;
            display: flex;
            margin: .4rem;
            cursor: pointer;
            &:hover{
                color: rgb(255, 255, 255);
            }
            @media screen and (max-width: 550px) {
                font-size: 18px;
            }
        }
    }
}

