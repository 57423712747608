.app__ourcamp-wrapper{
    width: 100%;
    background-color: var(--color-white);

  

    .app__ourcamp-content{
        width: 100%;
        background: var(--color-white);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 3rem 0;

        h2{
            font-size: 32px;
            font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
            font-weight: 600;
            text-align: center;
            color: var(--color-darkblue);
            width: 50%;
            line-height: 44px;
            margin: 2rem 0;
            // text-transform: capitalize;
        }
        p{
            font-size: 19px;
            font-weight: 500;
            font-family: 'Avenir';
            color: var(--color-darkblue);
            width: 80%;
            text-transform: capitalize;
            text-align: center;
        }
    }
    .app__ourcamp-camp{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        
        h1{
            font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
            font-size: 34px;
            font-weight: 600;
            // color: var(--color-darkblue);
        
        }
        p{
            font-family: 'Avenir';
            width: 80%;
            margin-top: 2rem;
            font-size: 19px;
            font-weight: 500;
            // color: var(--color-darkblue);
            text-align: center;
        }
    }

    // .app__ourcamp-cards{
    //     position: relative;
    //     width: 300px;
    //     height: auto;
    //     display: flex;
    //     flex-wrap: wrap;
    //     justify-content: flex-start;
    //     align-items: center;
    //     flex-direction: column;
    //     margin:2rem 1rem;
    //     box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

    //     .card-img{
    //         position: absolute;
    //         width: 300px;
            
    //         img{
    //             width: 100%;
    //             object-fit: cover;
    //         }
    //     }
    //     .cards-price{
    //         position: absolute;
    //         bottom: -5rem;
    //         left: 0;
            
    //     }
    // }

}