
* {
  outline: none;
}

.app__coombtin {
  -webkit-appearance: none;
          appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
         filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
          transition: all .5s ease;
  // border: none;
  background: var(--color-algeagreen);
  border-radius: 0px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 900;
  font-size: 16px;
  font-family: var(--font-alt);
  font-family: var(--font-best);
  font-weight: normal;
  letter-spacing: 2px;
  border-radius: 0;
  background-color:rgb(156, 9, 156);
  // text-transform: capitalize;
  cursor: pointer;
  // @media screen and (max-width: 550px) {
  //   // letter-spacing: 1px;
  //   font-size: 16px;
  // }
  a{
    color: #ffffff;
  }
}

.app__coombtin:hover {
  a{
    color: #fff;
  }
  color: #FFFFFF;
  background: #222222;
 
}


.slider-wrapper-comm {
  position: relative;
  height: 100vh;
  overflow: hidden;
  padding-top: 5.6rem;

  @media screen and (max-width: 650px) {
    padding-top: 4.6rem;
  }
}

// .slide {
//   height: 100vh;
//   background-size: cover !important;
// }

// .slide::before {
//     content: '';
//     display: block;
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
//     background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
//     bottom: 0;
//     left: 0;
// }

.slider-wrapper-comm{
  .previousButton, .nextButton {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;
		background: url('../../../../assets/right.svg') no-repeat center center / 16px;
		width: 42px;
		height: 42px;
		text-indent: -9999px;
        background-color: var(--color-algeagreen);
        border-radius: 100px;
		cursor: pointer;
    display: none;

    @media screen and (max-width: 650px) {
      display: none;
    }
}
}
  

.previousButton:hover, .nextButton:hover {
  background-color: var(--color-darkblue);
}

.previousButton {
  left: 10px;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
          transform: rotate(180deg) translateY(calc(50% + 0px));
}

.nextButton {
  right: 10px;
}


.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  @media screen and (max-width: 600px) {
    padding: 0 10px;
  }
}

.slider-content .inner .pp__coombtin{
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
          border-radius: 0px!important;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #FFFFFF;
  font-size: 60px;
  font-family: var(--font-alt);
  line-height: 1;
  
  @media screen and (max-width: 550px) {
    // max-width: 900px;
    font-size: 45px;
  }
}

.slider-content .inner p {
  color: #FFFFFF;
  font-size: 19px;
  font-family: var(--font-best);
  font-weight: 500;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 880px;

  @media screen and (max-width: 650px) {
    font-size: 15px;
    width: 100%;
  }
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #FFFFFF;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #FFFFFF;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {
  .slider-wrapper-comm, .slide {
    height: calc(100vh - 75px);
  }    
}

@media (max-width: 640px) {
  .slider-wrapper-comm, .slide {
    height: calc(80vh - 75px);
  }    
}
@media (max-width: 550px) {
  .slider-wrapper-comm, .slide {
    height: calc(85vh - 75px);
  }    
}

// @media (max-height: 600px) {
//   .slider-content .inner h1 {
//     font-size: 30px;
//     max-width: 900px;
//   }    
// }

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 40px;
    max-width: 950px!important;
  }
}