.app__whereweare-head{
    width: 100%;
    margin: 3rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h1{
        font-family: var(--font-alt);
        text-transform: capitalize;
        font-size: 39px;

    }
    div{
        width: 100%;
        max-width: 100px;
        padding-top: 1rem;
        height: 1px;
        border-bottom: 3px solid var(--color-black);
    }
}
.app__whereweare-bg{
    background-image: url('../../assets/bg2.jpg');
    width: 100%;
    height: 60vh;
    background-position:center;
    // background-attachment: fixed;
    background-size: cover;
    -webkit-box-shadow: inset 0 0 100px 10px #000;    
    -moz-box-shadow: inset 0 0 10px 10px #000;
    box-shadow: inset 0 0 400px 100px rgba(0, 0, 0, 0.8);
    position: relative;
    padding: 2rem;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;

    h1{
        // position: absolute;
        padding-top: 1.7rem;
        color: #fff;
        font-family: var(--font-alt);
        font-size: 38px;
        text-transform: uppercase;
        display: flex;
        width: 100%;

        @media screen and (max-width: 650px) {
            font-size: 30px;
        }
    }
    div{
        width: 200px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        height: 1px;
        border-bottom: 2px solid var(--color-white);
        // position: absolute;
        margin: 1rem 0
    }
    p{
        padding-top: 1rem;
        color: #fff;
        font-family: var(--font-best);
        font-size: 18px;
        max-width: 750px;
        // text-align: center;
    }
}


.app__whereweare-content-inner{
    width: 90%;
    margin: 3rem auto 1rem;

    p{
        font-family: var(--font-best);
        font-size: 18px;
        line-height: 30px;
        // color: #777;
        a{
            text-decoration: underline;
            color: var(--color-algeagreen);
            &:hover{
                color: var(--color-darkblue);
            }
        }

    }
    a{
        color: darkcyan;
        text-decoration: underline;
    }
}

.whereweare-btn{
    width: 90%;
    margin: auto;
    padding-top: 1rem;

    button{

        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            margin-left: 5px;
            font-size: 20px;
        }
    }
}