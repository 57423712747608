
// .app__wrap{
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     height: 100%;
//     margin-top: 0rem;
// }
// .app__links-icon_heading{
//     margin-top: 4rem;
// }
// .app__links-icon_heading h1{
//     font-family: var(--font-best);
//     font-size: 28px;
//     font-weight: 500;
//     color: var(--color-darkblue);
// }
// .app__links-icon_heading .app__links-line{
//     width: 100px;
//     height: 2px;
//     margin: auto;
//     margin-top: 2rem;
//     margin-bottom: 2rem;
//     border-bottom: 1px solid  var(--color-blue);
// }


// .app__content-format{
//     display: flex;
//     flex-direction: column;
// }
// .app__content-format .app__content-format_name{
//     font-family: var(--font-best);
//     font-size: 19px;
//     color: var(--color-darkblue);
//     font-weight: 500;
// }
// .app__content-format .app__founder{
//     font-weight: 400;
//     font-size: 20px;
//     color: #24365987;
//     font-family: var(--font-best);
//     text-align: start;
//     font-size: 17px;
// }

// .app__links-icon_svg{
//     margin-top: 2rem;
//     margin-bottom: 4rem;
// }

// .app__links-icons svg{
//     margin: 0 1.4rem ;
//     font-size: 1.3rem;
//     background-color: #f4f4f4;
//     color: #818a91;
//     cursor: pointer;
   
// }

// .app__links-icons svg:hover{
//     color:#00c3ff;

// }

// .app__wrap img{
//     width: 16%;
//     border-radius: 100%;
//     object-fit: cover;

// }

// .app__header-content{
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     margin-top: 1rem;
//     align-items: center;
//     margin-right: 20rem;
//     justify-content: center;
// }

// .app__header-content_quote{
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin:0 10rem;
// }
// .app__header-content_quote svg{
//     width: 4rem;
//     height: 4rem;
//     margin-bottom: 7rem;
//     margin-right: 1rem;
//     opacity: 1;
// }

// .app__header-content_quote p{
//     font-family: var(--font-best);
//     color: var(--color-darkblue);
//     font-size: 18px;
//     text-align: start;
//     margin-right:2rem;
//     letter-spacing: 0.03;
//     font-weight: 400;
// }
// .headtext__format {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
//     font-size: 4rem;
// }
// .app__header-content_quote .app__text-p{
//     font-size: 19px;
//     font-family: var(--font-alt);
//     font-weight: 500;
// }
// .text__format{
//     margin-left: 4rem;
//     align-items: center;
//     justify-content: center;
//     /* text-align: center; */
// }


// @media screen and (max-width: 1180px) {
//     .app__header-content_quote svg{
//         width: 7rem;
//         height: 7rem;
//         margin-bottom: 10rem;
//         margin-right: 1rem;
//         opacity: 1;
//     }
// }

// @media screen and (max-width: 950px) {
//     .app__header-content_quote svg{
//         width: 7rem;
//         height: 7rem;
//         margin-bottom: 15rem;
//         margin-right: 1rem;
//         opacity: 1;
//     }   
// }
// @media screen and (max-width: 800px) {
//     .app__header-content_quote svg{
//         width: 7rem;
//         height: 7rem;
//         margin-bottom: 18rem;
//         margin-right: 1rem;
//     }   
// }

// @media screen and (max-width: 600px) {
//     .app__header-content_quote svg{
//         width: 6rem;
//         height: 6rem;
//         margin-bottom: 28rem;
//         margin-right: 1rem;
//         opacity: 1;
//     }
// }




// .head-text {
//     font-size: 2.75rem;
//     font-family: var(--font-best);
//     font-weight: 500;
//     text-align: center;
//     color: var(--color-darkblue);
//     text-transform: capitalize;
  
//     span {
//       color: var(--secondary-color);
//     }
  
//     @media screen and (min-width: 2000px) {
//       font-size: 4rem;
//     }
  
//     @media screen and (max-width: 450px) {
//       font-size: 2rem;
  
//     }
//   }
  
//   .p-text {
//     font-size: 17px;
//     text-align: left;
//     color: var(--color-darkblue);
//     line-height: 1.5;
//     font-family: var(--font-best);
  
//     @media screen and (min-width: 2000px) {
//       font-size: 1.75rem;
//     }
//   }
  

.app__header-text{
    text-align: center;
    font-size: 2rem;
    font-family: var(--font-best);
    font-weight: 500;
    text-align: center;
    color: var(--color-darkblue);
    text-transform: capitalize;
    span {
         color: var(--secondary-color);
    }
    @media screen and (max-width:650px) {
        font-size: 25px;
        text-align: start;
        
        
    }
          
   
}
.app__container-content{
    h2{
        text-align: center;
        font-size: 1.75rem;
        margin: 2rem 1rem 1rem;
        font-family: var(--font-best);
        font-weight: 500;
        text-align: center;
        color: var(--color-darkblue);
        text-transform: capitalize;
        @media screen and (max-width:950px) {
            font-size: 1.3rem;
            margin: 0 1rem 0;
   
        }
        @media screen and (max-width:650px) {
            font-size: 1rem;
        }  
    }
    p{
        text-align: center;
        margin: 2rem 1rem 2rem;
        font-size: 1.1rem;
        font-family: var(--font-best);
        font-weight: 400;
        text-align: start;
        color: var(--color-darkblue);
        text-transform: capitalize;
        @media screen and (max-width:650px) {
            font-size: 1rem;
        }  
    }
}
.app__image-details{
    display: flex;
    width: 500px;
    height: 400;
    justify-content: space-evenly;
    margin: 1rem 2rem 1rem;
    img{
        width: 100%;
        margin:0 2rem 0;
    }
}
.app__underline{
    width: 200px;
    height: 1px;
    margin: auto;
    margin-top: 1.5rem;
    border: 1px solid var(--color-blue);

}