.app__aboutgallery-head{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0; 
    h1{
        width: 100%;
        font-size: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;

        &::after{
            border-bottom: 4px solid var(--color-black);
            bottom: -18px;
            content: '';
            left: 50%;
            position: absolute;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            width: 5%;
        }
    }
}

.app__about-bgnow{
    width: 100%;
    height: 70vh;
    position: relative;
    background-image: url('../../../assets/use/grapes.jpg');
    background-position: center;
    background-size: cover;
    // background-attachment: fixed;
    position: relative; 
    -webkit-box-shadow: inset 0 0 100px 10px #000;    
    -moz-box-shadow: inset 0 0 10px 10px #000;
    box-shadow: inset 0 0 400px 100px rgba(0, 0, 0, 0.8);

    .app__about-bgnow-content{
        width: 100%;
        max-width: 850px;
        position: absolute;
        top: 15%;
        padding-left: 2rem;
        h1{
            font-size: 60px;
            font-family: var(--font-alt);
            color: #fff;
            line-height: 70px;
            text-transform: capitalize;
            span{
                color: brown;
            }
            @media screen and (max-width:850px) {
                font-size: 40px;
                line-height: 50px;
            }
        }
        p{
            font-family: var(--font-alt);
            width: 70%;
            font-size: 15px;
            // line-height: 37px;
            color: #fff;
            @media screen and (max-width:850px) {
               width: 90%;
            }
        }
        h4{
            padding-top: 2rem;
            font-family: var(--font-alt);
            max-width: 650px;
            font-size: 17px;
            color: #fff;
            font-weight: 500;

        }


    }
}

.app__g-content{
    width: 90%;
    // padding-left: 2rem;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    h4{
        font-size: 16px;
        font-weight: 500;
        font-family: var(--font-alt);
        width: 100%;
        padding-right: 10px;
    }
}

.app__aboutcustom-img{
    width: 90%;
    display: flex;
    // margin-top: 3rem auto;
    flex-direction: column;
    margin: auto;
    // box-shadow: 0px 0px 30px rgba(0,0,0,.1);
    @media screen and (max-width: 1150px) {
        flex-direction: column;
        width: 100%;
    }
    .app__aboutcustom-content{
        // width: 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        // padding: 0 1rem ;
        margin: auto;
        // padding-left: 2rem;
        // box-shadow: 0px 0px 30px rgba(0,0,0,.1);
        @media screen and (max-width: 1150px) {
            width: 90%;
        }

        p{
            width: 90%;
            // font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
            font-family: var(--font-alt);
            font-size: 15px;
            font-weight: 400;
            color: var(--color-darkblue);
            margin-bottom: 1rem;
            @media screen and (max-width: 1150px) {
                padding-right: 5px;
                width: 100%;
            }
        }
        h2{
            font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
            text-transform: uppercase;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            font-weight: 600;
            font-size: 11px;
            letter-spacing: 3px;
            line-height: 1.8;
            background: var(--color-black);
             color: #fff;
             text-align: center;
             margin-top: 1rem;
             padding:1.2em 2rem;
             cursor: pointer;
             display: flex;
             align-items: flex-start;
             justify-content: flex-start;

             &:hover{
                background: transparent;
                border: 1px solid var(--color-black);
                transition: .3s ease-in-out;
                color: #000;
             }
        }
    }
    .aboutcustom-gallery{
        // margin-top: 3rem;
        width: 100%;
        // padding: 1rem;
        // margin-left: 1rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        // padding: 1rem;
        margin: auto;
        gap: 10px;
        @media screen and (max-width: 1150px) {
            padding: 1rem;
        }
        .aboutcustom-image{
            width: 100%;
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
            margin-bottom: 8px;
            padding: 10px;
            border-top-right-radius: 15px;
            img{
                width: 100%;
                height: 200px;
                object-fit: cover;
                border-top-right-radius: 15px;
            }

        }
        @media screen and (max-width: 1150px) {
            width: 100%;
        }
        @media screen and (max-width: 950px) {
            width: 100%;
        }
        @media screen and (max-width: 650px) {
            width: 100%;
        }
        .app__aboutcustom-image{
            border-radius: 3px;
            transform: scale(0);
            opacity: 0;
            animation: popOut .8s forwards;
            &:nth-child(2){
                animation-delay: .1s;
            }
            &:nth-child(3){
                animation-delay: .2s;
            }
            &:nth-child(6){
                animation-delay: .3s;
            }
            &:nth-child(5){
                animation-delay: .4s;
            }
            &:nth-child(4){
                animation-delay: .5s;
            }
         
        }

        @media screen and (max-width: 550px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 1vw;
            
        }
    }
}
.app__cont{
    font-size: 15px;
    width: 90%;
    // padding-left: 2rem;
    margin: auto;

    p{
        font-family: var(--font-alt);
        font-weight: 500;
        color: var(--color-darkblue);
    }
}

.app__ntm{
    width: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 2rem;
    margin-bottom: 2rem;
    margin: 0 auto 2rem;
    @media screen and (max-width: 650px) {
        width: 100%;
    }
    // margin: auto;
    // padding-left: 1rem;
    // padding-left: 2rem;
    button.one, button.two{
            margin-bottom: 1rem;
            font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
            text-transform: uppercase;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 3px;
            line-height: 1.5;
            background: #000;
            color: #fff;
            cursor: pointer;
            margin-right: 1rem;
            // max-width: 75%;
            padding: .8rem 1.5rem;
            border-radius: 5px;
            @media screen and (max-width: 420px) {
                margin: auto;
                padding: .8rem 1rem;
                letter-spacing: 1px;
                margin: 0 5px;
            }
            &:hover{
                background-color: var(--color-algeagreen);
                transition: .5s ease-in-out;
                color: #000;
                // border: 1px solid var(--color-black);
            }

    }
    button.three{
        background-color: var(--color-algeagreen);
        color: #000;
        border-radius: 5px;
        &:hover{
            color: #fff;
            background-color: var(--color-black);
        }
    }
}