.app__background-header{
    width: 100%;
    height: 100vh;
    position: relative;
    // margin-top: 4rem;
    
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .app__overlay-camp{
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.3);
    }
    .content{
        position: absolute;
        color: var(--color-white);
        bottom: 3rem;
        h1{
            font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
            font-weight: 600;
            font-size: 40px;
            line-height: 66px;
            bottom: 30px;
            margin-left: 4rem;
            width: 60%;
        }

    }
}