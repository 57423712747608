.app__container-linereer{
    padding-top: 5.7rem;

    @media screen and (max-width: 650px) {
        padding-top: 4.7rem;
    }
}

.app__img-bghistrwe{
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    -webkit-box-shadow: inset 0 0 100px 10px #000;    
    -moz-box-shadow: inset 0 0 10px 10px #000;
    box-shadow: inset 0 0 400px 100px rgba(0, 0, 0, 0.9);
    background-position: center;
    background-size: cover;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem;

    @media screen and (max-width:650px) {
      padding: 2rem;
  
    }

    @media screen and (max-width:500px) {
      height: 60vh;
      padding: 1rem;
  
    }

    h1{
        color: #fff;
        margin: 2rem auto ;
        font-size: 75px;
        line-height: 100px;
        font-weight: normal;
        font-family: var(--font-alt);
        text-align: center;
        
      @media screen and (max-width:550px) {
        font-size: 45px;
        line-height: 55px;
        letter-spacing: 2px;
    
      }
    }
    p{
      color: #fff;
      max-width: 900px;
      margin: 0 auto;
      text-align: center;
      font-size: 19px;
      line-height: 29px;
      font-family: var(--font-best);
      @media screen and (max-width: 650px) {
        font-size: 16px;
        line-height: 26px;
        max-width: 1120px;
      }
      @media screen and (max-width: 550px) {
        font-size: 14px;
        line-height: 24px;
        max-width: 1120px;
      }
      
    }
}
.app__history-timelinenew {
    // display: flex;
    width: 90%;
    margin: 4rem auto 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    @media screen and (max-width: 950px) {
        grid-template-columns: repeat(1, 1fr);
    }

    // background-color: #031625;
  
    &:hover {
      .tl-item {
        // width: 23.3333%;
        width: 100%;
      }
    }
  }
  
  .tl-item {
    transform: translate3d(0, 0, 0);
    position: relative;
    // width: 25%;
    width: 100%;
    // height: 100vh;
    min-height: 600px;
    color: #fff;
    overflow: hidden;
    transition: width 0.5s ease;
    cursor: pointer;
    
    &:before, &:after {
      transform: translate3d(0, 0, 0);
      content: '';
      position: absolute;
      left: 0; top: 0;
      width: 100%; height: 100%;
    }
  
    &:after {
      background: transparentize(#031625, 0.15);
      opacity: 1;
      transition: opacity 0.5s ease;
    }
  
    &:before {
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 75%);
      z-index: 1;
      opacity: 0;
      transform: translate3d(0, 0, 0) translateY(50%);
      transition: opacity 0.5s ease, transform 0.5s ease;
    }
  
    &:hover {
      width: 100% !important;
      
      &:after {
        opacity: 0;
      }
  
      &:before {
        opacity: 1;
        transform: translate3d(0, 0, 0) translateY(0);
        transition: opacity 1s ease, transform 1s ease 0.25s;
      }
      
      .tl-content {
        opacity: 1;
        transform: translateY(0);
        transition: all 0.75s ease 0.5s;
      }
  
      .tl-bg {
        filter: grayscale(0);
        width: 100%;
      }
    }
  }
  
  .tl-content {
    transform: translate3d(0, 0, 0) translateY(25px);
    position: relative;
    z-index: 1;
    text-align: center;
    margin: 0 1.618em;
    top: 55%;
    opacity: 0;
    
    h1 {
    //   font-family: 'Pathway Gothic One',Helvetica Neue,Helvetica,Arial,sans-serif;
      font-family: var(--font-alt);
      font-size: 35px;
      text-transform: capitalize;
      color: aliceblue;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      letter-spacing: 2px;
      // margin: 10px auto;
      line-height: 45px;
      font-weight: normal;
    }

    p{
        font-size: 19px;
        font-family: var(--font-best);
        
    }
  }
  
  .tl-year {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    // border-top: 1px solid #fff;
    // border-bottom: 1px solid #fff;
    
    p {
    //   font-size: 1.728rem;
      font-size: 39px;
      font-family: var(--font-best);
      line-height: 55px;
      // line-height: 0;
    }
  }
  
  .tl-bg {
    transform: translate3d(0, 0, 0);
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    background-size: cover;
    background-position: center center;
    transition: filter 0.5s ease;
    filter: grayscale(100%);
  }
  
  