
.app__products-detailsss{
    // max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem auto 0;
    // background-color: rgba(5, 5, 29,);
    // background-color: rgba(8, 8, 34, .7);
    padding: 2rem;   
}


.app__details{
    // width: 100%;
    // max-width: 600px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // margin: 2rem auto 0;
    // background-color: rgb(12, 12, 54);
    p{
        // padding: 1rem;
        margin-bottom: 2rem;
        width: 100%;
        font-size: 48px;
        line-height: 60px;
        font-family: var(--font-best);
        font-family: "SJ Sans", "Open Sans", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
        color: #fff;
        color: #000;
        color: #d11947;

        @media screen  and (max-width:650px) {
            font-size: 35px;
            line-height: 40px;
        }
    }

    .amounttt{
        font-size: 24px;
        font-family: var(--font-best);
        font-weight: normal;
        margin-bottom: 1rem;

        @media screen and (max-width: 550px) {
            font-size: 22px;
        }
    }

    .app__monthlybthn{
        width: 100%;
        display: flex;
        // background-color: darkcyan;
        margin-bottom: 3rem;
        border: 1px solid #999999;
        button{
            width: 100%;
            border-radius: 0;
        }
        .one{
            background-color: transparent;
            color: #000;
            text-transform: capitalize;
            color: #135cb0;
            font-family: var(--font-best);
            font-family: "SJ Sans", "Open Sans", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
            font-weight: normal;
            font-size: 16px;
            &:hover{
                background-color: #135cb0;
                color: #fff;
            }
        }
        .two{
            background-color: #135cb0;
            color: #fff;
            font-weight: normal;
            font-family: var(--font-best);
            font-family: "SJ Sans", "Open Sans", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
            text-transform: capitalize;
            font-size: 16px;
            &:hover{
                background-color: transparent;
                color: #135cb0;
            }
        }
    }
}
.app__choose-amount{
    // max-width: 600px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0rem auto;
    // background-color: rgb(12, 12, 54);
    // padding: 2rem;
    gap: 10px;

    @media screen and (max-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
        
    }

    @media screen and (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
        
    }

    .one{
        width: 100%;
        background-color: aquamarine;
        // background-color: aliceblue;
        background-color: #fff;
        border: .8px solid var(--color-black);
        // padding: 1.5rem;
        padding: 10px;
        // border-radius: 30px;
        // height: 100px;
        min-height: 10px;
        &:hover{
            background-color: transparent;
            background-color: #135cb0;
        }
        cursor: pointer;
    }
}