
.app__slick-wrapper{
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin: auto;
    .app__slick-wrap{
        height: 90vh;
        width: 100%;
        display: flex;
        .app__slick-content{
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100vh;
            // margin: 3rem 5rem;
            margin: auto;

            display: flex;
            .app__slick-left{
                            // width: 40%;
            flex-direction: column;
            // height: 100%;
            padding-left: 2rem;
            
            margin: auto;
            
            font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
            h1{
                font-size: 29px;
                // width: 60%;
                line-height: 44px;
                color: var(--color-darkblue);
                letter-spacing: .1px;
            }
            p{
                font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
                font-size: 19px;
                width: 60%;
                margin: 2rem 0;
                font-weight: 500;
                color: var(--color-darkblue);
            }
            h2{
                    font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
                    text-transform: uppercase;
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    font-weight: 600;
                    font-size: 11px;
                    letter-spacing: 3px;
                    line-height: 1.8;
                    background: #fc0;
                     color: #000;
                     width: 30%;
                     text-align: center;
                     margin-top: 1rem;
                     padding:.8em 1.8rem;
                     cursor: pointer;
                }
            }
            .app__slick-right{
                        width: 100vw;
                        height: 70vh;
            
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                
                    }
        }
    }

}

