// background: #FFFFFF;
//     border: 1px solid #EBEFFA;
//     box-sizing: border-box;
//     box-shadow: 0px 10px 20px rgb(0 58 208 / 5%);
//     border-radius: 4px;

.app__newhist-head{
    width: 100%;
    text-align: center;
    padding: 3rem 0 1rem;

    h1{
        font-family: var(--font-alt);
        font-weight: normal;
        font-size: 45px;
    }
    .hwadme{
        width: 50px;
        height: 10px;
        border-bottom: 3px solid var(--color-black);
        margin: 0 auto 1rem;
    }
}


.app__newhistory{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem auto;
    @media screen and (max-width:950px) {
        width: 90%;
    //    flex-direction: column-reverse;
    }
    @media screen and (max-width:750px) {
        width: 90%;
       flex-direction: column-reverse;
    }

    .img{

        width: 50%;
        padding: 0 10px;
        min-height: 600px;
        background: #FFFFFF;
        border: 1px solid #EBEFFA;
        margin-right: 2rem;
        box-sizing: border-box;
        box-shadow: 0px 10px 20px rgb(0 58 208 / 5%);
        border-radius: 4px;
        position: relative;
        @media screen and (min-width:1200px) {
            width: 50%;
            // margin: auto;
        }
        @media screen and (max-width:750px) {
            width: 100%;
            // margin: auto;
        }
        
        p{
          
            padding-top: 2rem;
            padding-bottom: 1rem;
            line-height: 37px;
            font-size: 20px;
            font-family: var(--font-best);
            font-weight: normal;
            text-align: center;

        }
        h1{
            padding-top: 1rem;
            font-size: 16px;
            font-family: var(--font-best);
            color: #24365987;
            font-weight: normal;
            width: 100%;
            margin: auto;
            text-align: center;
        }
        img{
            position: absolute;
            bottom: 0;
            // width: 100%;
            max-width: 250px;
            object-fit: cover;
            height: 250px;
            left: 20%;

            // @media screen and (max-width:750px) {
            //    left: 1rem;
            // }
        }


    }

    .cont{
        width: 50%;
        padding-left: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
        // @media screen and (min-width:750px) {
            //    max-width: 400px;
            // }
            @media screen and (max-width:750px) {
                width: 100%;
                font-family: var(--font-best);
                font-size: 18px;
                line-height: 33px;
                padding-left: 0;
                margin-bottom:2rem;
        }

        p{
            width: 100%;
            font-family: var(--font-best);
            font-size: 16px;
            line-height: 35px;
            // max-width: 450px;
            @media screen and (max-width: 850px) {
                width: 100%;
                // min-width: 450px;
            }
        }
        a{
            width: 100%;
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            
            &:hover{
                
                text-decoration: underline;
                transition: .3s ease;
            }
        }
        h2{
            font-family: var(--font-best);
            font-weight: normal;
            font-size: 25px;
            text-align: left;
            color: rgb(23, 23, 88);
            margin: 10px 0;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            // padding-top: 1rem;
            // margin: 0;
            // padding: 0;
            // width: 100%;
            // padding-left: 0!important;
            // text-align: left!important;
            // line-height: 34px;
            &:hover{
                color: #24365987;
            }
        }
    }
}