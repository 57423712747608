.app__news{
    background-color: #080E3F;
    background-color: rgb(0, 0, 0, 0.3)!important;
    width: 100%;
    // min-height: 60vh;
    // padding: 2rem;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 999;
    overflow: hidden;
    width: 100%!important;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    -webkit-box-shadow: inset 0 0 100px 10px rgb(128, 0, 128);    
    -moz-box-shadow: inset 0 0 10px 10px rgb(128, 0, 128);
    box-shadow: inset 0 0 100px 400px rgba(0,0,0);
    background-position: center;
    background-size: cover;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem;
    // @media screen and (min-width: 2000px) {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     justify-content: center;
    // }
    @media screen and (max-width: 1150px) {
        flex-direction: column;
        height: auto;
    }
    .cont{
        width: 80%;
        margin: auto;
        // background-color: #fff;
        @media screen and (max-width: 850px) {
            width: 100%;
        }
        @media screen and (max-width: 950px) {
            width: 100%;
            margin: auto;
        }
        .connt{
            width: 100%;
            h1{
                color: #FFFFFF;
                font-size: 28px;
                font-weight: 800;
                line-height: 45px;
                font-family: var(--font-best);
                @media screen and (max-width: 550px) {
                    font-size: 23px;
                    line-height: 33px;
                }
            }
            p{
                color: #FFFFFF;
                font-size: 23px;
                font-weight: 500;
                font-family: var(--font-alt);
                max-width: 400px;
                line-height: 33px;
                @media screen and (max-width: 550px) {
                    font-size: 18px;
                    line-height: 27px;
                }
            }
        }
        .app__ews-letter{
            width: 100%;
            padding-top: 3rem;
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: center;
            justify-content: flex-start;
            @media screen and (max-width: 1150px) {
                flex-direction: column;
            }
            .labelme{
                // position: relative;
                display: flex;
                flex-direction: column;
                width: 100%;
                font-family: var(--font-alt);
                .labels-two{
                    position: relative;
                    width:100%;
                    display: flex;
                    align-items: center;
                    font-family: var(--font-alt);
                    padding-top: 1rem;
                    justify-content: space-between;
                    @media screen and (max-width: 1150px) {
                        width: 100%;
                    }
        
                    input{
                        width: 80%;
                        background-color: #FFFFFF00;
                        border-color: #3A417CC2;
                        border-bottom: 3px solid #3A417CC2;
                        border-width: 0px 0px 2px 0px;
                        border-radius: 0px 0px 0px 0px;
                        padding-bottom: 15px;
                        padding-top: 15px;
                        padding-left: 10px;
                        font-family: 'Avenir' !important;
                        font-size: 18px;
                        font-weight: 300;
                        color: white;
                        @media screen and (min-width: 1150px) {
                            width: 40%;
                        }
                        @media screen and (max-width: 1150px) {
                            width: 70%;
                        }
                        @media screen and (max-width: 750px) {
                            width: 100%;
                        }
                    }
                }
                .labels-one{
                    // position: relative;
                    width:100%;
                    display: flex;
                    align-items: center;
                    font-family: var(--font-alt);
                    padding-top: 1rem;
                    justify-content: space-between;
                    input{
                        width: 80%;
                        background-color: #FFFFFF00;
                        border-color: #3A417CC2;
                        border-bottom: 3px solid #3A417CC2;;
                        border-width: 0px 0px 2px 0px;
                        border-radius: 0px 0px 0px 0px;
                        padding-bottom: 15px;
                        padding-left: 10px;
                        padding-top: 15px;
                        font-family: 'Avenir' !important;
                        font-size: 18px;
                        font-weight: 300;
                        color: white;
                        @media screen and (min-width: 1150px) {
                            width: 40%;
                        }
                        @media screen and (max-width: 1150px) {
                            width: 70%;
                        }
                        @media screen and (max-width: 750px) {
                            width: 100%;
                        }
                    }

                }
                
            }

            .nbtn{
                display: flex;
                align-items: center;
                justify-content: center;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-top: 1.4rem;
                width: 100%;
                @media screen and (max-width: 1150px) {
                    width: 100%;
                    padding-left: 0;
                }
                button{
                    
                    background-color: transparent;
                    border-color: #9edc1a!important;
                    color: white;
                    height: 47px;
                    display: flex;
                    width: 154px;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    border-style: solid;
                    border-width: 2px 2px 2px 2px;
                    font-family: var(--font-alt);
                    text-transform: capitalize;
                    font-weight: normal;
                    font-size: 18px;
                    letter-spacing: 1px;
                    border-radius: 5px;

                    &:hover{

                        background-color: #9edc1a;
                    }
                    @media screen and (max-width: 1150px) {
                        width: 70%;
                    }
                    @media screen and (max-width: 750px) {
                        width: 100%;
                    }
                }
            }
        }
        .take-btn{
            padding-top: 8px;
            display: none;
            p{
                font-family: var(--font-alt);
                font-size: 14px;
                // letter-spacing: 1px;
                color: #fff;
                padding-bottom: 9px;
                line-height: 20px;
                // font-weight: 900;
                @media screen and (max-width: 1150px) {
                    width: 80%;
                }
                @media screen and (max-width: 750px) {
                    width: 100%;
                }
            }
            .container-btn{
                width: 100%;
                display: flex;
                align-items: flex-start;
                @media screen and (max-width: 850px) {
                    display: block;

                }
                button{
                    font-family: 'GeoBrandBold', 'CenturyGothic', AppleGothic, Gill Sans, Gill Sans MT, sans-serif;
                    text-transform: capitalize;
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    font-weight: 600;
                    font-size: 14px;
                    letter-spacing: 1px;
                    line-height: 1.5;
                    color: #fff;
                    cursor: pointer;
                    border-radius: 3px;
                    padding: .8rem 3rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--color-algeagreen)!important;
                    position: relative;
                    &:hover{
                        // background-color: var(--color-algeagreen);
                        transition: .5s ease-in;
                        background-color: transparent!important;
                        border: 1px solid var(--color-white);
                    }
                    // @media screen and (max-width: 1150px) {
                    //     width: 80%;
                    // }
                    // @media screen and (max-width: 750px) {
                    //     width: 100%;
                    // }
                }
            }
        }
    }
}

.success-m{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1rem 0;
    p{
        color: aquamarine;
        font-family: var(--font-alt);
        font-size: 30px;
        text-transform: uppercase;
        line-height: 40px;
    }
}