.app__bg-bg{
    width: 100%;
    height: 60vh;
    position: relative;
    background-image: url('../../../../../assets/photos/achieve3.jpeg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    position: relative; 
    -webkit-box-shadow: inset 0 0 10px 10px #000;    
      -moz-box-shadow: inset 0 0 10px 10px #000;
      box-shadow: inset 0 0 200px 300px rgba(0, 0, 0, 0.3);
    .app__bg-content{
        
        position: absolute;
        color: #fff;
        top: 2rem;
        left: 1rem;
    }
    h1{
        font-family: var(--font-alt);
        font-size: 28px;
    }
    div .one{
        width: 90%;
        margin: 1rem 0;
        border-bottom: 1px solid var(--color-white);
    }
    p{
        font-family: var(--font-alt);
        font-size: 16px;
        letter-spacing: .1px;
        font-weight: 500;
    }
}
.app__plantation-model{
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: .6rem;
    margin: 10px auto;
    @media screen and (max-width: 650px) {
        grid-template-columns: repeat(2, 1fr);
    }

    .content{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 10px;
        border-radius: 15px;
        cursor: pointer;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
        &:hover{
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);

        }
        img{
            width: 100%;
            height: 130px;
            object-fit: cover;
            border-radius: 10px;
        }
        h1{
            font-family: var(--font-alt);
            font-size: 16px;

        }
    }
    
}

.app__bg-bg1{
    width: 100%;
    height: 60vh;
    position: relative;
    background-image: url('../../../../../assets/photos/achieve4.jpeg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    position: relative; 
    -webkit-box-shadow: inset 0 0 10px 10px #000;    
      -moz-box-shadow: inset 0 0 10px 10px #000;
      box-shadow: inset 0 0 200px 300px rgba(0, 0, 0, 0.3);
    .app__bg-content{
        
        position: absolute;
        color: #fff;
        top: 2rem;
        left: 1rem;
    }
    h1{
        font-family: var(--font-alt);
        font-size: 28px;
    }
    div .one{
        width: 90%;
        margin: 1rem 0;
        border-bottom: 1px solid var(--color-white);
    }
    p{
        font-family: var(--font-alt);
        font-size: 16px;
        letter-spacing: .1px;
        font-weight: 500;
    }
}